.subscription-plan-content {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__item {
    display: flex;
    gap: 8px;

    &__check {
      color: var(--color-white-lightest, #ffffff);
    }

    &__title {
      color: var(--color-white-lightest, #ffffff);
    }
  }
}
