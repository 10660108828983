.chart-legends {
  display: flex;
  gap: 24px;
  width: 100%;
  padding: 16px 8px;
  &__item {
    display: flex;
    align-items: center;
    gap: 4px;
    &__name {
      color: var(--color-gray-medium, #5f6368);
    }
  }
}
