.energy-value-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  &__value {
    font-weight: var(--font-weight-semi-bold, 500) !important;
    line-height: 16px !important;
    color: var(--color-black, #131314) !important;
  }
  &__value-title {
    line-height: 12px !important;
    color: var(--color-gray-medium, #5f6368) !important;
    margin-top: 4px !important;
  }
}
