.peak-energy {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: var(--color-gray-base, #b5b5bc) !important;

  &__icon {
    width: 22px;
    height: 22px;
  }

  &__score {
    font-size: var(--font-size-m, 16px);
  }

  &__score-group {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    cursor: pointer;
  }

  &__tooltip {
    --custom-tooltip-width: 300px;

    &__icon {
      color: var(--color-gray-base, #b5b5bc);
      font-size: 1rem !important;
      cursor: pointer;
      margin-left: 20px;
      height: 24px !important;
      width: 24px !important;
    }
  }
  &__no-data__tooltip {
    background-color: var(--color-red-base, #f34720) !important;
    padding: 0px !important;
    margin-right: 48px !important;
    &__icon {
      color: var(--color-red-base, #f34720) !important;
      height: 24px !important;
      width: 24px !important;
    }
  }
}
