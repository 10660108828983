@import '../../../utils/styles/mixins/_responsive';

.discount-code-dialog {
  .MuiPaper-root {
    width: 100% !important;

    @include responsive('desktop') {
      width: 60% !important;
    }
  }

  &__error {
    color: var(--color-red-base, #f34720);
    font-size: var(--font-size-sm, 13px);
    font-weight: var(--font-weight-semi-bold, 500);
  }

  &__success {
    color: var(--color-green-base, #09c25a) !important;
  }

  &__cancel {
    color: var(--color-gray-dark, #7c7c7c) !important;
  }
}
