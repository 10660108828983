.zone-label-tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__title-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
  }

  &__title {
    font-size: var(--font-size-s, 14px);
    font-weight: var(--font-weight-bold, 600);
    color: var(--color-black, #131314);
  }

  &__time {
    font-size: var(--font-size-xxs, 10px);
    font-weight: var(--font-weight-semi-bold, 500);
    border: solid 1px;
    align-self: flex-end;
    padding: 2px 4px;
    border-radius: 4px;
  }

  &__expla {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-gray-dark, #7c7c7c);
  }
}
