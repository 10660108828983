.ai-scheduler-text {
  background: linear-gradient(135deg, #8560f9 0%, #5685bd 66.5%, #09c25a 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-weight: var(--font-weight-med-bold) !important;
  display: inline;
  white-space: nowrap;
}
