.energy-pref-settings {
  display: flex;
  flex-direction: column;
  gap: 14px;

  &__title {
    color: var(--color-black);
    font-size: var(--font-size-m, 16px);
  }

  &__grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }
}
