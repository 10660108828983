.todos {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__accordion {
    &::before {
      height: 0 !important;
    }

    &.Mui-expanded {
      margin: 0 !important;
    }

    .MuiAccordionDetails-root {
      padding: 0 6px 0 0 !important;
    }
    .MuiAccordionSummary-root {
      padding: 0 !important;
      min-height: 32px !important;

      &.Mui-expanded {
        min-height: auto !important;
      }

      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
    }

    &__projects {
      display: flex;
      flex-direction: column;
    }
  }

  &__more__icon {
    width: 20px !important;
    color: var(--color-black, #131314) !important;
  }

  &__button {
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-black, #131314) !important;
    height: 32px !important;

    &__icon {
      width: 20px;
      height: 18px;

      svg {
        width: 20px;
        height: 18px;
      }
    }

    &__text {
      font-size: var(--font-size-s, 14px);
    }
  }
}
