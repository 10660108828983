.ai-scheduler-practice-pref {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-purple);
  gap: 16px;
  padding: 16px 10px;
  box-shadow: 0 0 0.5px rgba(0, 0, 0, 0.18);
  width: 100%;

  &__header {
    font-size: var(--font-size-m, 14px);
    color: var(--color-black, #131314);
    font-weight: 500;
  }

  &__description {
    font-size: var(--font-size-sm, 13px);
    color: var(--color-black, #131314);
  }

  &__item {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__item-title {
    font-size: var(--font-size-sm, 13px);
    color: var(--color-gray-dark, #7c7c7c);
    flex: 1;
  }

  &__item-duration {
    font-size: var(--font-size-s, 12px);
    color: var(--color-gray-dark, #7c7c7c);
    font-weight: bold;
    flex: 3;
  }

  &__item-time {
    font-size: var(--font-size-s, 12px);
    color: var(--color-gray-dark, #7c7c7c);
    font-weight: 400;
  }
}
