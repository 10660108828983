.energy-gauge-card {
  &__energy-value {
    font-weight: var(--font-weight-bold, 600) !important;
    color: var(--color-black, #131314);
    line-height: 32px !important;
  }

  &__energy-unit {
    color: var(--color-gray-medium, #5f6368);
    line-height: 12px !important;
    margin-top: 4px !important;
  }

  &__gauge-chart {
    height: 96px !important;
  }
}
