.task-time-project {
  --dropdown-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__time-group {
    display: flex;
    flex-direction: row;
    gap: 4px;

    &__time {
      border-radius: 8px;
      border: solid 1px var(--color-gray-base, #b5b5bc);
      color: var(--color-gray-base, #b5b5bc);
      font-size: var(--font-size-s, 14px);
      padding: 8px;
      font-weight: var(--font-weight-semi-bold, 500);
    }

    &__phase {
      font-size: var(--font-size-s, 14px);
      color: white;
      padding: 8px;
      border-radius: 8px;
    }
  }

  &__project {
    width: var(--dropdown-width, 180px);
    font-size: var(--font-size-s, 14px);
    color: var(--color-black, #131314);
    font-weight: var(--font-weight-semi-bold, 500);
    border-radius: 8px;
    padding: 8px;
    border: 1px solid transparent;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background-color: var(--color-gray-purple, #f9f8fc);
    }
    cursor: pointer;
    &--open {
      border: 1px solid var(--color-black, #131314);
    }
    &--disabled {
      cursor: not-allowed;
    }

    &--empty {
      color: var(--color-gray-base, #b5b5bc);
      border-color: var(--color-gray-lightest, #e8eaed);

      &:hover {
        border-color: var(--color-purple-taro, #c9b8ff);
        color: var(--color-purple-taro, #c9b8ff);
      }
    }

    &__text {
      font-size: var(--font-size-m, 16px) !important;
      color: var(--color-black, #131314) !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__icon {
      width: 16px;
      display: flex;
    }
  }

  &__project-dropdown {
    .MuiPaper-root {
      border-radius: 12px !important;
      width: var(--dropdown-width, 180px) !important;
      box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a);
      margin-top: 5px;
      min-height: 128px;
    }
    &__item {
      $self: &;
      gap: 12px !important;
      transition: all 0.3s ease;
      padding: 12px 16px !important;
      justify-content: flex-start !important;

      &:hover {
        background-color: var(--color-gray-purple, #f9f8fc) !important;

        #{$self}__text {
          color: var(--color-black, #131314) !important;
        }
      }
      &--selected {
        color: var(--color-black, #131314) !important;
      }

      &__text {
        font-size: var(--font-size-m, 16px) !important;
        color: var(--color-gray-base, #b5b5bc) !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__icon {
        width: 16px;
        display: flex;
      }

      &.Mui-disabled {
        cursor: not-allowed !important;
      }
    }
  }
}
