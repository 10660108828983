.data-event-dashboard {
  &__box {
    background-color: var(--color-white-lightest, #ffffff);
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
    border-radius: 24px;
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  }
  &__title {
    font-weight: var(--font-weight-med-bold, 700) !important;
  }
  &__inner_box {
    margin-top: 16px;
    background-color: var(--color-white-light);
    padding: 12px;
    border-radius: 14px;
    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__title {
      font-weight: var(--font-weight-semi-bold, 500) !important;
    }
    &__description {
      color: var(--color-gray-dark, #7c7c7c);
    }
  }
}
