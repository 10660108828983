.mobile-app-container {
  .mobile-app-title {
    color: #131314;
    font-size: var(--font-size-m, 16px);
  }

  .mobile-app-subtitle {
    color: #5f6368;
    font-size: 13px;
    margin: 8px 0px 4px 0px;
  }

  .mobile-app {
    background-image: url('../../../../assets/onboarding/onboardingContainer.png');
    width: 100%;
    height: 118px;
    display: flex;
    flex-direction: row;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .app-previews {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      left: 57px;
      bottom: 0;

      img {
        width: 100%;
        height: 107px;
        object-fit: contain;

        &:first-child {
          margin-bottom: -7.88px;
        }
      }
    }

    .store-links {
      display: flex;
      align-items: center;
      gap: 16px;
      position: absolute;
      right: 22px;
      top: 51px;

      a img {
        width: 110px;
        height: 32px;
        object-fit: contain;
      }
    }
  }
}
