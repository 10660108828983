.ai-scheduler-practice-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiBackdrop-root {
    background-color: var(--color-black-subtle, #13131466) !important;
    backdrop-filter: blur(10px) !important;
  }

  &__container {
    background-color: var(--color-white-lightest, #ffffff);
    border-radius: 24px;
    width: 800px;
    height: 560px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
  }

  &__close {
    cursor: pointer;
    color: var(--color-gray-dark, #666666);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all 0.2s ease;
    position: absolute;
    top: 30px;
    right: 33px;
    font-size: 25px !important;

    &:hover {
      color: var(--color-black, #000000);
      background-color: var(--color-gray-lighter, #f5f5f5);
    }
  }
}
