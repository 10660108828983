.event-time-selector {
  display: flex;
  gap: 10px;
  color: var(--color-black, #131314);

  &__icon-container {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    color: var(--color-gray-base, #b5b5bc) !important;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  &__time {
    display: flex;
    align-items: center;

    .MuiInputBase-input {
      height: 16px !important;
      font-size: var(--font-size-m, 16px) !important;
      width: 100%;
      padding: 8px 0px !important;
    }

    .MuiButtonBase-root {
      padding: 0 !important;

      .MuiSvgIcon-root {
        display: none;
      }
    }
  }
}
