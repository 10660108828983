.energy-bar-label {
  display: flex;
  flex-direction: column;
  gap: 3px;

  &__bar-container {
    border-radius: 14px;
    position: relative;
  }

  &__label-group {
    display: flex;
    width: 100%;

    &__item {
      flex: 1;
      text-align: center;
      font-size: var(--font-size-xxs, 10px);
      font-weight: 300;
      color: var(--color-gray-base, #b5b5bc);

      &--selected {
        color: var(--color-primary, #680ddb);
      }
    }
  }
}
