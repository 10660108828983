@import '../../../utils/styles/mixins/_responsive';

.connect-todo-page {
  width: 100%;

  &__asset {
    width: 100%;
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    padding: 20px;

    @include responsive('desktop') {
      padding: 20px 0 20px 100px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    gap: 30px;
    width: 100%;
    color: var(--color-white-lightest, #ffffff);

    &__title {
      font-family: var(--font-family-nunito) !important;
    }

    @include responsive('desktop') {
      gap: 60px;
    }
  }

  &__btn {
    border-radius: 12px !important;
    width: 100%;
    height: 64px;
    margin-top: 30px !important;

    @include responsive('desktop') {
      margin-top: auto !important;
    }
  }

  &__additional-children {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-white-lightest, #ffffff);
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
