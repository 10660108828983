.edit-sleep-modal {
  padding: 60px 40px;
  display: flex;
  gap: 16px;
  width: 100%;
  flex: 1;

  &__bg {
    &--container {
      flex: 1;
    }
    width: 100%;
    object-fit: contain;
    border-radius: 24px;
  }

  &__description-container {
    flex: 1;
    padding: 8px 8px 0px 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__date-picker {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__item {
      .MuiInputAdornment-root > button {
        height: auto !important;
      }
    }
  }

  &__description {
    font-size: var(--font-size-ml, 18px);
    line-height: 27px;

    &__copy {
      font-size: var(--font-size-m, 16px);
      line-height: 27px;
      color: var(--color-gray-darkest, #131314);
    }
  }

  &__button {
    &--loading {
      background-color: var(--color-gray-lightest, #e8eaed) !important;
    }
    &--disabled {
      background-color: var(--color-gray-base, #b5b5bc) !important;
    }
  }
}
