.notification-settings {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &__title {
    color: #131314;
    font-size: var(--font-size-m, 16px);

    &__light {
      color: #5f6368;
      font-size: var(--font-size-xs, 12px);
    }
  }
}
