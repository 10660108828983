.connect-todos-list-item {
  border-radius: 12px !important;
  justify-content: space-between !important;
  padding: 10px 16px !important;

  $self: &;

  &--not-connected {
    border: dashed 1px var(--color-white-lightest, #ffffff) !important;

    #{$self}__content {
      color: var(--color-white-lightest, #ffffff) !important;
    }

    &:hover {
      background-color: var(--color-white-lightest, #ffffff) !important;

      #{$self}__content {
        color: var(--color-primary, #680ddb) !important;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  &__icon {
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }
}
