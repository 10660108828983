:root {
  --color-white-lightest: #ffffff;
  --color-white-light: #fcfcfe;
  --color-primary: #680ddb;
  --color-secondary: #09c25a;
  --color-black: #131314;
  --color-black-subtle: #13131466;
  --color-black-light: #0000001a;
  --color-black-mid: #0000004d;
  --color-black-lightest: #0000000d;
  --color-gray-dark: #7c7c7c;
  --color-gray-base: #b5b5bc;
  --color-gray-light: #d9d9d9;
  --color-gray-lightest: #e8eaed;
  --color-gray-medium: #5f6368;
  --color-gray-purple: #f9f8fc;
  --color-red-base: #f34720;
  --color-orange-base: #ff8760;
  --color-green-base: #09c25a;
  --color-yellow-base: #fdc913;
  --color-blue-base: #3a92fa;
  --color-blue-light: #97c7ff;
  --color-blue-lightest: #cfe2f3;
  --color-blue-med: #9fc5e8;
  --color-blue-med-light: #6fa8dc;
  --color-blue-bright: #4499df;
  --color-blue-slate: #3c5c9acc;
  --color-blue-slate-light: #3c5c9a29;
  --color-purple-taro: #c9b8ff;
  --color-purple-light: #ebe5fd;
  --color-purple-taro-light: #c9b8ff33;
  --color-purple-taro-lightest: #c9b8ff4d;
  --color-primary-dark: #3e0ca4;

  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 13px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-ml: 18px;
  --font-size-l: 20px;
  --font-size-xl: 24px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 40px;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 600;
  --font-weight-med-bold: 700;
  --font-weight-extra-bold: 800;

  --font-family-nunito: 'Nunito Sans Variable';

  --header-height: 64px;
  --core-actions-container-height: 132px;
}
