@import '../../../../utils/styles/mixins/_responsive';

.energy-pref-selector {
  gap: 0px !important;

  @include responsive('desktop') {
    gap: 10px !important;
  }

  .MuiRadio-root {
    color: var(--color-white-lightest, #ffffff) !important;
  }
}
