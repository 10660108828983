.manage-calendars-btn {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 8px 0px;
  color: var(--color-gray-black, #131314);
  font-size: var(--font-size-s, 14px);
  cursor: pointer;
  background-color: transparent;
  border: none;
  height: 32px !important;

  &--container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
  }

  &__error {
    color: var(--color-red-base, #f34720);
    cursor: pointer;
  }

  &__icon {
    height: 18px !important;
    width: 20px !important;
  }

  &__popover {
    .MuiPaper-root {
      border-radius: 16px;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 0px #0000001a;
      width: 220px;
    }
    transform: translateX(-6px) !important;
  }

  &__modal {
    border-radius: 16px;
    background-color: #ffffff;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 13px 24px;
      gap: 21px;
      font-weight: var(--font-weight-bold, 800);
      font-size: var(--font-size-s, 14px);

      p {
        margin: 0;
      }
    }

    &__content {
      padding: 0 24px 32px 24px;
      width: 100%;
      display: flex;
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    &__item {
      padding: 8px !important;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #f9f8fc !important;
      border-radius: 8px !important;
      font-size: var(--font-size-sm, 13px) !important;
      justify-content: space-between !important;
      color: #680ddb !important;
      border: 1px solid transparent !important;
      &:hover {
        border: #c9b8ff 1px solid !important;
        background-color: #c9b8ff33 !important;
      }
      &__text {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      &__icon {
        width: 16px !important;
        height: 16px !important;
        padding-bottom: 1px;
      }
    }
    &__close-button {
      padding: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;

      &__icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
