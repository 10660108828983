.view-switcher {
  display: flex;
  align-items: center;
  width: 112px;
  height: 56px;
  position: relative;
  border: 2px solid transparent;
  border-radius: 24px;
  background-color: var(--color-white-lightest);
  box-shadow: 0px -4px 24px 0px var(--color-black-light);

  &__button {
    width: 50%;
    height: 100%;
    position: relative;
    border: none;
    border-radius: 24px !important;
    background-color: transparent;
    color: var(--color-gray-medium);
    cursor: pointer !important;
    z-index: 1;
    transition: color 0.3s ease;

    &--active {
      color: var(--color-white-lightest);
    }
  }

  &__indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    border-radius: 24px;
    background-color: var(--color-primary);
    transition: transform 0.3s ease;

    &--right {
      transform: translateX(100%);
    }
  }
}
