.energy-tooltip {
  &__indicator {
    position: absolute;
    padding: 0;
    transform: translate(-15%, -50%);
    width: 24px;
    height: 24px;
    background-color: var(--color-primary, #680ddb);
    border-radius: 50%;
    border: solid 5px var(--color-white-lightest, #ffffff);
    pointer-events: auto;
    z-index: 1;
  }
}
