.settings-modal {
  .MuiBackdrop-root {
    background-color: #13131466 !important;
    backdrop-filter: blur(4px) !important;
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 14px;
    background-color: var(--color-white-lightest, #ffffff);
    display: flex;
    flex-direction: column;
    outline: none !important;
    height: 560px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px var(--color-gray-light, #d9d9d9);

    &__title {
      font-size: var(--font-size-l, 20px);
      font-weight: var(--font-weight-bold, 600);
    }

    &__close {
      cursor: pointer;
    }
  }

  &__main {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 0;

    &__content {
      padding: 12px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
      scroll-behavior: smooth;

      &__section {
        border-top: 1px solid var(--color-gray-lightest, #e8eaed);
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-top: 24px;

        &__title {
          font-size: 20px;
          color: var(--color-black, #131314);
          font-weight: 400;
        }

        &:first-child {
          border-top: none;
          padding-top: 8px;
        }
      }
    }
  }
}
