.card-payment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--color-white-lightest, #ffffff);
  padding: 20px 12px;
  border-radius: 16px;

  &__btn {
    border-radius: 12px !important;
    width: 100%;
    height: 52px;
  }

  &__error {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-red-base, #f34720);
  }
}
