.calendars-settings {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-size: var(--font-size-m, 16px);
    color: var(--color-black, #131314);
    margin-bottom: 8px;
  }

  &__grid {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__calendar-item {
    color: #b5b5bc;
    display: flex;
    align-items: center;
    gap: 9.5px;
    font-size: var(--font-size-sm, 13px);
  }

  &__view {
    .MuiButtonBase-root {
      padding: 6px !important;
      display: flex;
      gap: 4px;
      align-items: center;
      color: var(--color-black, #131314);
      background-color: var(--color-gray-purple, #f9f8fc);
    }
    .Mui-selected {
      background-color: var(--color-purple-taro-light, #c9b8ff33) !important;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__item {
    &__title {
      font-size: var(--font-size-xs, 12px);
      color: var(--color-black, #131314);
    }

    &__description {
      font-size: 11px;
      color: var(--color-gray-dark, #7c7c7c);
    }
  }

  &__default-calendar {
    &__dropdown {
      border-radius: 6px;
      border: solid 1px var(--color-gray-base, #b5b5bc) !important;
      width: 400px;

      --calendar-account-dropdown-selected-text-font-size: var(--font-size-xs, 12px);
      --calendar-account-dropdown-selected-text-color: var(--color-black, #131314);

      .MuiSelect-select {
        padding: 0 !important;
      }
    }
  }

  &__item-content {
    &--group {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-purple, #f9f8fc);
    border-radius: 6px;
    padding: 6px;
    width: fit-content;

    &__title {
      font-size: var(--font-size-xs, 12px);
      color: var(--color-gray-dark, #7c7c7c);
    }
  }

  &__transparency-group {
    &__availability-toggle {
      width: 120px;
      height: 24px;
      --toggle-buttons-font-size: var(--font-size-xxs, 10px) !important;
    }
  }

  &__sync-calendar {
    width: 180px;

    &__label {
      margin: 0 !important;

      .MuiFormControlLabel-label {
        font-size: var(--font-size-xs, 12px) !important;
        color: var(--color-gray-dark, #7c7c7c) !important;
      }
    }
  }

  &__calendar-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    color: var(--color-white-lightest, #ffffff);
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(95, 99, 104, 0.2);
  }

  &__calendly {
    width: 150px;
    margin-top: 10px !important;
    font-size: var(--font-size-xxs, 10px) !important;
  }
}
