.manual-sync-button {
  &__container {
    width: 100%;
  }

  &__button {
    width: 100%;
    padding: 8px;
    background: var(--color-purple-taro-light, #c9b8ff33);
    border: solid 1px var(--color-purple-taro, #c9b8ff);
    border-radius: 10px;
    cursor: pointer;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &:hover:not(:disabled) {
      opacity: 0.8;
    }

    &__icon {
      width: 16px !important;
      height: 16px !important;
      color: var(--color-black, #131314);

      &.is-rotating {
        animation: rotate 2s linear infinite;
      }
    }

    &__text {
      font-size: var(--font-size-sm, 13px);
      color: var(--color-black, #131314);
    }
  }

  &__minimized {
    color: var(--color-gray-base, #b5b5bc) !important;

    .manual-sync-button__button__icon {
      width: 24px !important;
      height: 24px !important;
      color: var(--color-gray-base, #b5b5bc);
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
