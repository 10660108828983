.task-table {
  flex: 1;
  &__header {
    color: var(--color-gray-medium, #5f6368) !important;
  }
  &__row-item {
    font-weight: var(--font-weight-semi-bold, 500) !important;
  }

  .MuiTableCell-root {
    border: none;
  }
}
