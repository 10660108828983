.add-project-btn {
  display: flex;
  flex-direction: row;
  gap: 5px;

  &__icon {
    color: var(--color-gray-dark, #7c7c7c);
    font-size: var(--font-size-sm, 13px) !important;
  }

  &__text {
    font-size: var(--font-size-sm, 13px);
    color: var(--color-gray-dark, #7c7c7c);
  }
}
