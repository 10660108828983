.data-view {
  display: flex;
  height: 100%;
  width: 100%;

  &__header {
    padding: 0 16px;
    display: flex;
    align-items: center;
    min-height: 65px;
    border-bottom: solid 1px var(--color-gray-lightest, #e8eaed);
    background-color: var(--color-white-lightest, #ffffff);
    position: sticky;
    top: 0px;
    z-index: 20;
    &__title {
      border-radius: 16px;
      color: var(--color-gray-base, #b5b5bc);
      background-color: var(--color-white-lightest, #ffffff);
      padding: 8px 12px;
      border: solid 1px var(--color-gray-light, #d9d9d9);
      cursor: pointer;
    }
  }

  &__menu-bar {
    flex: 1;
  }
  &__dashboard {
    flex: 3;
    overflow-y: auto;
    background-color: var(--color-gray-purple, #f9f8fc);

    &__content {
      padding: 16px;
      height: 100%;
    }
  }
}
