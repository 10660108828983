.task-checkbox {
  width: var(--checkbox-width, 20px);
  height: var(--checkbox-height, 20px);
  min-width: var(--checkbox-width, 20px);
  min-height: var(--checkbox-height, 20px);
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &__inner {
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-gray-base);
    border-radius: 50%;
    background-color: var(--color-white-lightest);
    transition: all 0.2s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      width: calc(var(--checkbox-width, 20px) * 0.5);
      height: calc(var(--checkbox-height, 20px) * 0.5);
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 8.0251L0.5 5.0251L1.55 3.9751L3.5 5.9251L8.45 0.975098L9.5 2.0251L3.5 8.0251Z' fill='%237c7c7c'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .task-checkbox__inner::before {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  &--completed {
    .task-checkbox__inner {
      background-color: var(--checkbox-color, var(--color-primary));
      border-color: var(--checkbox-color, var(--color-primary));
      transform: scale(1);

      &::before {
        display: none;
      }
    }
  }
}
