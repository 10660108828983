.stepper {
  width: 100%;
  display: flex;
  gap: 2px;
  &__check {
    height: 9px;
    width: 12px;
  }
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__steps {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-m);

    &--active {
      background: var(--color-primary);
      color: var(--color-white-lightest);
    }

    &--inactive {
      background: var(--color-purple-taro);
      color: var(--color-white-lightest);
    }
  }

  &__line {
    width: 203px;
    height: 1px;
    border: 1px dashed var(--color-purple-taro);
    &--active {
      border: 1px solid var(--color-primary);
    }
  }

  &__text {
    margin-top: 8px;
    font-size: var(--font-size-s);
    line-height: 1.4;

    &--active {
      color: var(--color-gray-medium);
      font-weight: var(--font-weight-semi-bold, 500);
    }

    &--inactive {
      color: var(--color-gray-base);
    }
  }

  &__checkmark {
    width: 24px;
    height: 24px;
    background: var(--color-purple-taro);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);

    img {
      width: 12px;
    }
  }
}
