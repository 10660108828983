.add-project-dialog {
  .MuiPaper-root {
    width: 500px !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__app {
      display: flex;
      align-items: center;
      gap: 14px;

      &__icon {
        height: 20px !important;
        width: 20px !important;
        color: var(--color-gray-base, #b5b5bc) !important;
      }

      p {
        font-size: var(--font-size-sm, 13px);
        color: var(--color-gray-base, #b5b5bc);
      }
    }
  }
}
