.ai-scheduler-modal {
  padding: 40px;
  display: flex;
  gap: 16px;

  &--with-loader {
    margin: auto;
  }

  &__left-section {
    width: 440px;
    height: 360px;
    border-radius: 24px;
    border: solid 2px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;

    &--bg {
      background-color: var(--color-gray-purple, #f9f8fc);
      padding: 16px;
    }

    &__inside-container {
      border-radius: 16px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: auto;
    }
  }

  &__validation {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-red-base);
  }

  &__running {
    &--container {
      display: flex;
      flex-direction: column;
      gap: 35px;
      align-items: center;
    }

    &__image {
      width: 50%;
      object-fit: contain;
    }
  }

  &__header {
    background-image: url('../../../../../../assets/scheduler/daily-pref-header-container.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 88px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    &__title {
      font-size: var(--font-size-xs, 12px);
      font-weight: bold;
      color: var(--color-white-lightest, #ffffff);
    }

    &__subtitle {
      font-size: var(--font-size-s, 14px);
      color: var(--color-white-lightest, #ffffff);
    }
  }

  &__pref-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  &__gif {
    height: 236px;
    width: 420px;
    border-radius: 24px;
  }

  &__description-container {
    width: 264px;
    padding: 8px 8px 0px 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  &__description {
    font-size: var(--font-size-ml, 18px);
    line-height: 27px;
  }

  &__run-button {
    &--disabled {
      background-color: var(--color-gray-base, #b5b5bc) !important;
    }
  }

  &__version-toggle {
    margin: auto auto 0 auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px;

      &__title {
        font-size: var(--font-size-xs, 12px);
        color: var(--color-black, #131314);
      }

      &__buttons {
        width: 130px;
        height: 24px;
        --toggle-buttons-font-size: var(--font-size-xxs, 10px) !important;
      }
    }

    &__explanation {
      display: flex;
      flex-direction: column;
      gap: 4px;
      background-color: var(--color-gray-purple, #f9f8fc);
      border-radius: 8px;
      padding: 4px 6px;

      &__text {
        font-size: var(--font-size-xxs, 10px);
        color: var(--color-black, #131314);
        text-align: center;
      }

      &__caption {
        font-size: var(--font-size-xxs, 10px);
        color: var(--color-gray-dark, #7c7c7c);
        text-align: center;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__right-group {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }

    &__item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      font-size: var(--font-size-sm, 13px);
      color: var(--color-gray-base, #b5b5bc);

      &__icon {
        width: 13px !important;
        height: 13px !important;
      }
    }
  }
}
