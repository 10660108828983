.energy-item {
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--color-white-light);

  &__label-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__icon-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-light);

    &--blue {
      background-color: var(--color-blue-base) !important;
    }

    &--red {
      background-color: var(--color-red-base);
    }

    &--green {
      background-color: var(--color-green-base);
    }
  }

  &__flame-icon {
    height: 18px;
    width: 18px;
  }

  &__title-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__icon {
      color: var(--color-gray-base, #b5b5bc);
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__explanation-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-gray-medium);
  }

  &__fluctuation-wrapper {
    padding-left: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__fluctuation-item-group {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__gray-text {
    color: var(--color-gray-dark) !important;
  }
}
