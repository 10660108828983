.todo-list-title {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: var(--color-black, #131314) !important;

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__text {
    font-size: var(--font-size-s, 14px);
  }

  &__more {
    margin-left: auto !important;

    &__icon {
      width: 20px !important;
      color: var(--color-black, #131314) !important;
    }
  }
}
