.general-cta {
  display: flex;
  flex-direction: column;
  gap: 0px;

  &__button {
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    height: 32px !important;
  }

  &__item {
    color: var(--color-black, #131314) !important;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    height: 32px !important;

    &__icon {
      width: 20px;
      height: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__text {
      font-size: var(--font-size-s, 14px);
    }
  }

  &__popover {
    .MuiPaper-root {
      border-radius: 16px;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 0px #0000001a;
    }
    margin-top: -10px;
    margin-left: -6px;
  }

  &__modal {
    border-radius: 16px;
    background-color: #ffffff;

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 13px 0px;
      position: relative;
      font-weight: 700;
      font-size: var(--font-size-s, 14px);

      p {
        margin: 0;
      }
    }

    &__content {
      padding: 0 30px 20px 30px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__close-button {
      padding: 0;
      width: 18px !important;
      height: 18px !important;
      cursor: pointer;
      position: absolute !important;
      right: 12px;

      &__icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
