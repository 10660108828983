.dashboard-page {
  --calendar-width: 400px;
  --view-toggle-width: 48px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  background-color: var(--color-gray-purple, #f9f8fc);
  padding: 22px 18px;
  &__loader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__kanban-view--no-core-actions {
    height: 100%;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__calendar-view--no-core-actions {
    width: var(--calendar-width);
    height: 100%;
    flex: 0 0 auto;
  }

  &__sidebar-container {
    height: 100svh;
    padding: 22px 24px 22px 0;
  }

  &__side-bar {
    height: 100%;
    flex: 0 0 auto;
    background-color: var(--color-white-lightest, #ffffff);
    width: 100%;
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
    border-radius: 24px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100svh;
    padding: 22.5px 0px;
    width: calc(100% - 300px); // 300px to make the content fill the screen
  }

  &__core-actions {
    display: flex;
    justify-content: space-between;
    height: 88px;
  }

  &__kanban-calendar-container {
    height: calc(100svh - var(--core-actions-container-height));
    display: flex;
    width: 100%;

    &__kanban-view {
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      flex: 1;
    }

    &__calendar-view {
      width: var(--calendar-width);
      z-index: 1;
    }
  }

  &__content-view-toggle {
    width: var(--view-toggle-width);
  }

  &__calendar-week-month-view,
  &__loader {
    height: calc(100svh - var(--core-actions-container-height));
    display: flex;
    width: 100%;
    border-radius: 24px 0px 0px 24px;
    background-color: var(--color-white-lightest, #ffffff);
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
  }

  &__data-view-container {
    height: calc(100svh - var(--core-actions-container-height));
    width: 100%;
    background-color: var(--color-white-lightest);
    border-radius: 24px;
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
    overflow: hidden;
  }
}
