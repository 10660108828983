.calendar-account-dropdown {
  --calendar-account-dropdown-selected-text-font-size: var(--font-size-sm, 13px);
  --calendar-account-dropdown-selected-text-color: var(--color-gray-base, #b5b5bc);

  &__form-control {
    width: 100%;
    padding-right: 8px !important;
  }
  &__select::before {
    border-bottom: none !important;
  }

  &__menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__color-indicator {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 8px;
    border: 1px solid var(--color-gray-lightest, #e8eaed);
  }

  &__calendar-name {
    flex-grow: 1;
  }

  &__selected-value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-gray-base, #b5b5bc);
    &__text {
      color: var(--calendar-account-dropdown-selected-text-color);
      font-size: var(--calendar-account-dropdown-selected-text-font-size);
    }
    &__icon-container {
      height: 32px;
      width: 32px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}
