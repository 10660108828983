.event-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 4px;

  &__calendar {
    width: 5px;
    height: auto;
    min-height: 100%;
    min-width: 5px;
    align-self: stretch;
    border-radius: 4px 0px 0px 4px;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1px;
    width: 100%;

    &--short {
      flex-direction: row;
      gap: 2px;
      align-items: center;
    }

    &__title {
      font-weight: var(--font-weight-bold, 600) !important;
      word-break: break-all;
      width: 100%;
      line-height: 1 !important;
    }

    &__long-event-title-wrapper {
      display: flex;
      flex-direction: row;
      gap: 2px;
      align-items: center;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-right: 2px;
  }

  &__row-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
  }

  &__label-group {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;

    &__icon-wrapper {
      display: flex;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
    }

    &__text {
      font-weight: var(--font-weight-semi-bold, 500) !important;
      line-height: 1 !important;
      font-size: var(--font-size-xxs, 10px) !important;
    }
  }
}
