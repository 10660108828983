.content-view-switcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-white-lightest, #ffffff);
  border-radius: 0px 24px 24px 0px;
  box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
  gap: 16px;
  padding-top: 16px;
  border-left: solid 1px var(--color-gray-lightest, #e8eaed);
  &__button {
    height: 32px !important;
    width: 32px !important;
    border-radius: 8px !important;

    &__icon {
      height: 18px !important;
      color: var(--color-primary);
    }
    &--active {
      background-color: var(--color-purple-taro-lightest) !important;
    }
  }
}
