@import '../../../utils/styles/mixins/_responsive';

.onboarding-container {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/onboarding/onboardingContainer.png');
  padding: 30px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;

  @include responsive('desktop') {
    padding: 80px;
    flex-direction: row;
  }

  &__left-section {
    flex: 1;
    background: var(--color-white-lightest, #ffffff);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    height: 100%;

    &__logo {
      height: 30px;
      width: auto;
      object-fit: contain;

      &--container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include responsive('desktop') {
        height: 40px;
      }
    }

    &__asset {
      &--container {
        flex: 2;
      }
    }
  }

  &__right-section {
    flex: 2;

    @include responsive('desktop') {
      flex: 1;
    }
  }
}
