.edit-event-menu {
  .MuiPaper-root {
    position: absolute;
    bottom: 0;
    right: var(--calendar-width, 410px);
    width: 360px !important;
    border-radius: 24px;
    box-shadow: 0px 0px 5px var(--color-black-light, #0000001a);
    gap: 0px;
  }

  &__header {
    display: flex;
    padding: 24px 24px 10px 24px !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px 24px 0 0;
    border-bottom: solid 1px var(--color-gray-lightest, #e8eaed);
    background-color: var(--color-white-lightest, #ffffff) !important;

    p {
      font-size: var(--font-size-l, 20px);
      font-weight: var(--font-weight-med-bold, 700);
    }
    &__icon {
      color: var(--color-black, #131314) !important;
      width: 20px;
      cursor: pointer;
    }
  }

  &__content {
    padding: 24px !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--color-gray-base, #b5b5bc) !important;

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      &__icon {
        width: 32px !important;
        height: 32px !important;
        padding: 4px;
      }

      &__text {
        font-size: var(--font-size-sm, 13px);
      }
    }
  }

  &__title {
    width: 100%;
    .MuiInputBase-root {
      font-size: var(--font-size-l, 20px) !important;
      font-weight: var(--font-weight-semi-bold, 500) !important;
    }
    .MuiInputBase-root::before {
      border-bottom: none !important;
    }
  }
  &__title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
  &__actions {
    padding: 0px 20px 20px 20px !important;
  }
  &__phase-container {
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-x: auto;

    &__time {
      border-radius: 8px;
      border: solid 1px var(--color-gray-base, #b5b5bc);
      color: var(--color-gray-base, #b5b5bc);
      font-size: var(--font-size-s, 14px);
      padding: 8px;
      font-weight: var(--font-weight-semi-bold, 500);
      white-space: nowrap;
    }
    &__phase {
      font-size: var(--font-size-s, 14px);
      color: var(--color-white-lightest, #ffffff);
      padding: 8px;
      border-radius: 8px;
    }
  }
  &__button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 10px;

    &__button {
      color: var(--color-white-lightest, #ffffff) !important;
      font-size: var(--font-size-xs, 12px) !important;
      font-weight: var(--font-weight-bold, 600) !important;
      border-radius: 8px !important;
      width: 61px;
      height: 28px;
      line-height: 10px !important;

      &.delete-button {
        color: var(--color-black, #131314) !important;
      }
    }
  }
}
