.task-labels-select {
  flex: 1;
  padding-top: 2px;

  .MuiInputBase-root {
    background-color: transparent !important;
    gap: 4px !important;

    &::before,
    &::after {
      border-bottom: none !important;
    }
  }

  .MuiFormLabel-root {
    left: -12px !important;
  }

  .MuiFilledInput-input {
    font-size: var(--font-size-s, 14px) !important;
  }

  &__tag {
    height: auto !important;
    padding: 2px !important;
    border-radius: 6px !important;
    border: 0.5px solid var(--color-black, #131313) !important;

    &__label {
      display: flex;
      align-items: center;
      gap: 6px;

      &__color {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      &__name {
        font-size: var(--font-size-s, 14px);
        color: var(--color-black, #131313);
      }
    }

    &__delete-icon {
      font-size: 16px !important;
    }
  }

  &__menu {
    border-radius: 12px !important;
    box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a) !important;
    margin-top: 5px !important;
    max-height: 208px !important;
    padding: 4px !important;

    .MuiList-root {
      padding: 0 !important;
    }

    .MuiAutocomplete-option {
      padding: 4px !important;
      border-radius: 6px !important;
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  &__listbox {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__option {
      padding: 0 8px !important;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background-color: var(--color-gray-purple, #f9f8fc) !important;
      }

      &[aria-selected='true'],
      &--selected {
        background-color: var(--color-gray-purple, #f9f8fc) !important;
      }
    }
  }
}
