.default-pref-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  overflow-y: auto;

  &__item:first-child {
    grid-column: span 2;
  }
}
