.ai-scheduler-practice-setup {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  &__content {
    background-color: var(--color-gray-purple, #f9f8fc);
    border-radius: 24px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__header {
    padding: 12px 4px 4px 4px;

    &__subtitle {
      font-size: var(--font-size-s, 14px);
      color: var(--color-black, #131314);
      font-weight: var(--font-weight-bold, 600);
    }
  }

  &__validation {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-red-base);
  }

  &__pref-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    overflow-y: auto;
  }

  &__button {
    border-radius: 12px !important;
    width: 210px;
    height: 50px;
    order: 1;
    margin: auto !important;
    background: linear-gradient(135deg, #8560f9 0%, #5685bd 66.5%, #09c25a 100%);
    color: var(--color-white, #ffffff);

    &--disabled {
      background-color: var(--color-gray-light, #d9d9d9) !important;
      cursor: not-allowed !important;
    }
  }
}
