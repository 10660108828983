.subscription-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__plan {
    background-color: var(--color-purple-taro-light, #c9b8ff33);
    border: solid 0.5px var(--color-purple-taro, #c9b8ff);
    border-radius: 10px;
    padding: 20px 12px;

    &__title {
      font-size: var(--font-size-l, 20px);
      font-weight: var(--font-weight-bold, 600);
      color: var(--color-primary, #680ddb);

      &--small {
        font-size: var(--font-size-m, 16px);
        display: inline;
      }
    }

    &__description {
      font-size: var(--font-size-s, 14px);
      color: var(--color-primary, #680ddb);
    }
  }

  &__timeline {
    --subscription-timeline-text-color: var(--color-primary, #680ddb) !important;
    margin-top: 10px;
  }

  &__free-trial--wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__free-trial-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    font-size: var(--font-size-xs, 12px);
    font-weight: var(--font-weight-normal, 400);
    margin-top: 10px;
    &__inner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: var(--color-red-base, #f34720);
      }
    }
  }

  &__error {
    color: var(--color-red-base, #f34720);
    font-size: var(--font-size-s, 14px);
  }

  &__unsubscribe {
    align-self: flex-start;
    color: var(--color-red-base, #f34720) !important;
    border-color: var(--color-red-base, #f34720) !important;
    font-size: var(--font-size-s, 14px) !important;
  }

  &__account {
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding-top: 24px;
    font-size: 13px;
    border-top: 1px solid var(--color-border, #e0e0e0);

    > * {
      cursor: pointer;
    }

    &__delete {
      color: var(--color-red-base, #f34720);

      &--deleting {
        color: var(--color-gray-base, #666666);
        cursor: not-allowed;
      }
    }
  }
}
