.wearables-settings {
  &__title {
    margin-bottom: 8px;
    font-size: var(--font-size-m, 16px);
    color: #131314;
  }

  &__grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }
}
