.ai-scheduler-practice-result {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 24px;
  gap: 24px;

  &__calendar {
    overflow: hidden;
    min-width: 416px; //calendar width + border
    height: 100%;
    min-height: 0;
    border-radius: 24px;
    border: solid 8px var(--color-purple-taro, #c9b8ff);

    &__content {
      width: 400px;
    }
  }

  &__right-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 24px;
    padding: 30px 0;
  }

  &__header {
    text-align: left;
    font-size: var(--font-size-m, 16px);
    font-weight: var(--font-weight-bold, 600);
    color: var(--color-black, #131314);
    line-height: 2rem;
  }

  &__button {
    width: 100% !important;
    height: 48px;
    border-radius: 16px !important;
  }

  &__todo-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--color-gray-purple, #f9f8fc);
    border-radius: 14px;
    padding: 10px;
    width: 100%;
    min-height: 50px;
    justify-content: center;
    margin-bottom: auto;
    max-height: 270px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      height: 100%;
    }

    &__title {
      font-size: var(--font-size-m, 16px);
      color: var(--color-black, #131314);
      font-weight: var(--font-weight-bold, 600);
    }

    &__item-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      overflow-y: auto;
    }

    &__no-todos {
      font-size: var(--font-size-s, 14px);
      color: var(--color-gray-dark, #7c7c7c);
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: var(--color-white-lightest, #ffffff);
      border-radius: 8px;
      padding: 12px;
      gap: 12px;

      &__title {
        font-size: var(--font-size-s, 14px);
        font-weight: var(--font-weight-semi-bold, 500);
        color: var(--color-black, #131314);
      }

      &__time {
        font-size: var(--font-size-xs, 12px);
        color: var(--color-gray-dark, #7c7c7c);
      }
    }
  }
}
