.unsubscribe-dialog {
  .MuiPaper-root {
    width: 40% !important;
  }

  .MuiTextField-root {
    width: 100% !important;
  }

  &__discard {
    color: var(--color-blue-base, #3a92fa) !important;
  }

  &__cancel {
    color: var(--color-red-base, #f34720) !important;

    &--disabled {
      color: var(--color-gray-dark, #7c7c7c) !important;
    }
  }
}
