.custom-tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__title {
    font-size: var(--font-size-s, 14px);
    font-weight: var(--font-weight-bold, 600);
    color: var(--color-black, #131314);
  }

  &__description {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-black, #131314);
    white-space: pre-wrap;
    line-height: 1.2rem;
  }

  &__learn-more {
    width: 100px !important;
    margin-left: auto !important;
    border-radius: 8px !important;
    font-size: var(--font-size-xs, 12px) !important;
    background: var(--color-primary, #680ddb) !important;
    color: var(--color-white-lightest, #ffffff) !important;
    line-height: 1.2rem;
  }
}
