.task-due-date-picker {
  &__detail-group {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }

  &__add-time {
    font-size: var(--font-size-s, 14px) !important;
    color: var(--color-gray-dark, #7c7c7c);
  }

  &__date-picker {
    width: 160px;
    margin-left: -6px;
    ::before {
      border-bottom: none !important;
    }
    .MuiInputBase-input {
      padding-left: 6px;
      font-size: var(--font-size-sm, 13px) !important;
    }
  }

  &__popper {
    .MuiPickersLayout-root {
      display: flex !important;
      flex-direction: column !important;
    }
  }
}
