.energy-boost-modal {
  padding: 40px;
  display: flex;
  gap: 16px;

  &__bg {
    height: 360px;
    width: 440px;
    border-radius: 24px;
  }
  &__description-container {
    width: 264px;
    padding: 8px 8px 0px 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__description {
    font-size: var(--font-size-ml, 18px);
    line-height: 27px;
  }
  &__loading {
    background-color: var(--color-gray-lightest, #e8eaed) !important;
  }
}
