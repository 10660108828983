.subscription-plan-item {
  width: 100%;
  padding: 20px 16px;
  background-color: var(--color-white-lightest, #ffffff);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-purple-taro, #c9b8ff);
  position: relative;
  cursor: pointer;

  &--selected {
    background-color: var(--color-purple-taro, #c9b8ff);
    border-color: var(--color-primary, #680ddb);
  }

  &__title-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 6px 0;

    &__title {
      font-size: var(--font-size-l, 20px);
      font-weight: var(--font-weight-semi-bold, 500);
      color: var(--color-primary, #680ddb);
    }

    &__description {
      font-size: var(--font-size-s, 14px);
      color: var(--color-primary, #680ddb);
    }
  }

  &__best-deal {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 10px 4px 10px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: var(--color-primary, #680ddb);
    font-size: var(--font-size-xs, 12px);
    color: var(--color-white-lightest, #ffffff);
  }
}
