.cta-menu {
  &__button {
    height: 55px !important;
    min-height: 55px !important;
    width: 135px !important;
    border-radius: 16px !important;
    box-shadow: 0px 0px 5px var(--color-black-light, #0000001a) !important;
    color: var(--color-black, #131314) !important;
    font-size: var(--font-size-s, 14px) !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    font-weight: var(--font-weight-semi-bold, 500) !important;
  }

  &__menu {
    .MuiPaper-root {
      width: 150px !important;
      border-radius: 12px !important;
      background: var(--color-gray-purple, #f9f8fc) !important;
      border: solid 1px var(--color-black-light, #0000001a) !important;
      box-shadow: 0px 0px 5px var(--color-black-light, #0000001a) !important;

      .MuiList-root {
        padding: 0 !important;

        li {
          font-size: var(--font-size-s, 14px) !important;
          padding: 10px 16px !important;
        }
      }
    }
  }
}
