.week-task-chart {
  &__legends {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  &__legend {
    border-radius: 8px;
    padding: 3px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__legend-color {
    height: 8px;
    width: 8px;
    border-radius: 2px;
  }
}
