@import '../../../utils/styles/mixins/_responsive';

.paywall-page {
  width: 100%;

  &__asset {
    width: 100%;
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    padding: 10px;

    @include responsive('desktop') {
      padding: 0px 0 0px 100px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    gap: 30px;
    width: 100%;
    color: var(--color-white-lightest, #ffffff);
    justify-content: space-between;

    &__title {
      font-family: var(--font-family-nunito) !important;
    }

    &__timeline {
      padding: 0px;

      @include responsive('desktop') {
        padding: 0 10px;
      }
    }

    &__plans {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      overflow-y: auto;

      &__explanation {
        padding: 4px;
        @include responsive('desktop') {
          margin-bottom: 20px;
        }
      }

      &--loader {
        display: flex;
        justify-content: center;
        height: 100px;
        align-items: center;

        .MuiCircularProgress-root {
          color: white;
        }
      }
    }

    &__bottom-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 40px;

      &__button {
        border-radius: 12px !important;
        height: 52px !important;
        font-size: var(--font-size-ml, 18px) !important;
        color: var(--color-white-lightest, #ffffff) !important;
        font-weight: var(--font-weight-semi-bold, 500) !important;
      }
    }

    &__referral {
      font-size: var(--font-size-s, 14px);
      color: var(--color-white-lightest, #ffffff);
      text-decoration: underline;
      cursor: pointer;
      text-align: center;

      &--applied {
        cursor: default;
        text-decoration: none;
      }
    }

    @include responsive('desktop') {
      gap: 20px;

      &__bottom-group {
        margin-top: auto;
      }
    }
  }

  &__bottom-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-left: auto !important;
    position: inherit !important;
    right: 20px;
    bottom: 20px;

    &__text {
      font-size: var(--font-size-sm, 13px) !important;
      color: var(--color-white-lightest, #ffffff) !important;
    }

    @include responsive('desktop') {
      margin-top: 0px !important;
      position: absolute !important;

      &__text {
        font-size: var(--font-size-m, 16px) !important;
      }
    }
  }
}
