.sidebar {
  --sidebar-width: 240px;
  width: var(--sidebar-width);
  flex-direction: column;
  display: flex;
  position: relative;
  background-color: var(--color-white-lightest, #ffffff);
  overflow: hidden;

  &__content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 4px 12px;
    scrollbar-width: none;

    &__button {
      display: flex;
      gap: 10px;
      padding: 8px 12px;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      &__icon {
        width: 32px;
        height: 32px;
        padding: 3px;
      }
      &__text {
        font-size: var(--font-size-sm, 13px);
      }
    }
  }

  &__header {
    padding: 0 12px;
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--header-height, 64px);
    min-height: var(--header-height, 64px);
    justify-content: space-between;

    &__menu-icon {
      cursor: pointer;
      background-color: transparent;
      border: none;
    }

    &__logo {
      height: 28px;
      width: auto;
      object-fit: contain;
    }
  }

  &__calendar {
    &__content {
      width: 100% !important;
      min-height: 280px !important;
      height: auto !important;
      scrollbar-width: none;

      .MuiYearCalendar-root {
        width: calc(var(--sidebar-width, 240px) - 40px) !important;
        margin-left: 5px !important;
        box-shadow: 0px 0px 5px var(--color-black-light, #0000001a) !important;
      }

      .MuiPickersCalendarHeader-root {
        padding-left: 8px !important;
        padding-right: 0px !important;
        margin: 0 !important;
      }

      .MuiPickersSlideTransition-root {
        min-height: 200px !important;
      }

      .MuiPickersFadeTransitionGroup-root {
        font-size: var(--font-size-s, 14px) !important;
      }

      .MuiPickersYear-yearButton {
        font-size: var(--font-size-xs, 12px) !important;
      }

      .MuiPickersDay-root {
        margin: 0 !important;
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  &__settings {
    position: sticky;
    bottom: 0;
    margin-top: auto;
  }
}
