.color-picker-dropdown {
  &__menu {
    border-radius: 12px !important;
    box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a) !important;
  }

  &__content {
    padding: 4px 8px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__label {
    font-size: var(--font-size-m, 16px);
    color: var(--color-black, #131314);
    font-weight: var(--font-weight-semi-bold);
    padding: 8px;
    border-radius: 8px;
    background-color: var(--color-gray-purple, #f9f8fc);
    cursor: not-allowed;
  }

  &__divider {
    border-color: var(--color-gray-lightest, #e8eaed);
  }

  &__colors {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 4px;

    &__label {
      font-size: var(--font-size-m, 16px);
      color: var(--color-gray-base, #b5b5bc);
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px;
        justify-content: space-between;
        cursor: pointer;

        &__left {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &:hover {
          background-color: var(--color-gray-purple, #f9f8fc);
        }

        &__color {
          width: 18px;
          height: 18px;
          border-radius: 4px;
        }

        &__check {
          font-size: 16px !important;
          color: var(--color-gray-base, #b5b5bc);
        }
      }
    }
  }
}
