.ai-scheduler-settings {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  font-size: 13px;

  &__title {
    color: var(--color-black, #131314);
    font-size: var(--font-size-m, 16px);
  }

  &__subtitle {
    font-size: var(--font-size-sm, 13px);
    color: var(--color-gray-dark, #7c7c7c);
  }

  &__grid__item {
    background-color: var(--color-gray-purple, #f9f8fc);
    border-radius: 16px;
  }
}
