.task-label-select-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  $self: &;
  height: 40px;

  &:hover {
    // background-color: var(--color-gray-purple, #f9f8fc) !important;

    #{$self}__name {
      color: var(--color-gray-dark, #7c7c7c);
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__name {
    font-size: var(--font-size-m, 16px);
    color: var(--color-gray-base, #b5b5bc);
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__color-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &__more {
    color: var(--color-gray-base, #b5b5bc) !important;
    font-size: 18px !important;
  }
}
