.toggle-buttons {
  --toggle-buttons-font-size: var(--font-size-xs, 12px);

  width: 100%;

  .MuiToggleButton-root {
    flex: 1;
    text-transform: none;
    font-size: var(--toggle-buttons-font-size) !important;
    background-color: transparent;

    &.Mui-selected {
      background-color: var(--color-primary);
      color: white;

      &:hover {
        background-color: var(--color-primary);
        opacity: 0.9;
      }
    }
  }
}
