.no-wearable-chip {
  display: flex;
  border-radius: 4px;
  background-color: var(--color-white-lightest, #ffffff);
  flex-direction: column;
  gap: 10px;

  &__text {
    color: var(--color-black, #131314);
    font-size: var(--font-size-m, 16px);
    font-weight: var(--font-weight-semi-bold, 500);
  }

  &__cta {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__sync {
      background-color: var(--color-gray-purple, #f9f8fc);
      border-radius: 4px;
      padding: 6px;
      color: var(--color-gray-dark, #7c7c7c);
      font-size: var(--font-size-x, 14px);
    }
  }
}
