.extension-container {
  .extension-title {
    color: #131314;
    font-size: var(--font-size-m, 16px);
  }

  .extension-subtitle {
    color: #5f6368;
    font-size: 13px;
    margin: 8px 0px 4px 0px;
  }

  .extension {
    background-image: url('../../../../assets/onboarding/onboardingContainer.png');
    width: 100%;
    height: 118px;
    display: flex;
    flex-direction: row;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .extension-preview {
      position: absolute;
      left: 35px;
      bottom: -4px;

      img {
        width: 100%;
        height: 101px;
        object-fit: contain;
      }
    }

    a img {
      width: 113px;
      height: 32px;
      object-fit: contain;
      position: absolute;
      right: 84.05px;
      top: 51px;
    }
  }
}
