.time-format-toggle {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__title {
    font-size: var(--font-size-m, 16px);
    color: var(--color-black, #131314);
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__loader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 32px;
  }

  &__buttons {
    width: 200px;
    height: 32px;
  }
}
