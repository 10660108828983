.event-description {
  display: flex;
  flex-direction: row;

  &__icon {
    color: var(--color-gray-dark, #7c7c7c) !important;
  }

  &__input {
    flex: 1;
    .MuiInputBase-root {
      font-size: var(--font-size-m, 16px) !important;
    }
    .MuiInputBase-root::before {
      border-bottom: none !important;
    }
  }
}
