.todo-intg-settings {
  &__title {
    color: #131314;
    margin-bottom: 8px;
    font-size: var(--font-size-m, 16px);
  }

  &__grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    font-size: 13px;
    transition: all 0.3s ease;

    &--connected {
      border: 1px solid #c9b8ff;
      background-color: #c9b8ff33;
      color: #680ddb;
      cursor: not-allowed;
    }

    &--not-supported {
      border: none;
      background-color: transparent;
      color: #b5b5bc;
      cursor: not-allowed;
      background-color: #f9f8fc;
    }

    &--connectable {
      border: none;
      background-color: transparent;
      color: #680ddb;
      cursor: pointer;
      background-color: #f9f8fc;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid var(--color-primary, #680ddb);
        background-color: #f5f0ff47;
      }
    }
  }

  &__button-content {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
  }

  &__icon {
    height: 16px;
    width: 16px;
    margin-left: auto;
  }

  &__loading {
    margin: auto;
  }
}
