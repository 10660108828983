.sidebar-minimized {
    --sidebar-width: 74px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-purple, #f9f8fc);
    height: 100%;
  
    &__header__menu-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding-top: 16px;
    }
  
    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      gap: 16px;
      padding-bottom: 16px;
    }
  
    &__item {
      color: var(--color-black, #131314) !important;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
  
      &__icon {
        width: 24px;
        height: 24px;
        color: var(--color-gray-base, #b5b5bc);
      }
  
      &__text {
        font-size: 13px;
      }
    }
  
    &__avatar {
      height: 32px !important;
      width: 32px !important;
      border-radius: 8px !important;
    }
  
    &__popover {
      .MuiPaper-root {
        border-radius: 16px;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 0px #0000001a;
      }
      margin-top: -10px;
      margin-left: -6px;
    }
  
    &__modal {
      border-radius: 16px;
      background-color: #ffffff;
  
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 13px 24px;
        gap: 21px;
        font-weight: 700;
        font-size: 14px;
  
        p {
          margin: 0;
        }
      }
  
      &__content {
        padding: 0 30px 32px 30px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
  
      &__close-button {
        padding: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
  
        &__icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
