@import '../../../utils/styles/mixins/_responsive';

.energy-preference-page {
  width: 100%;

  &__asset {
    width: 100%;
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    padding: 20px;

    @include responsive('desktop') {
      padding: 20px 0 20px 100px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    gap: 10px;
    width: 100%;
    color: var(--color-white-lightest, #ffffff);

    &__title {
      font-family: var(--font-family-nunito) !important;
    }

    &__question {
      font-family: var(--font-family-nunito);
      font-weight: var(--font-weight-semi-bold, 500);
      line-height: 2.5rem;
      margin-bottom: 20px !important;
      font-size: var(--font-size-ml, 20px) !important;

      @include responsive('desktop') {
        font-size: var(--font-size-xl, 24px) !important;
      }

      &--strong {
        font-weight: var(--font-weight-med-bold, 700) !important;
        display: inline;
      }

      &__example {
        font-weight: var(--font-weight-normal, 400) !important;
        line-height: 1.5rem;
        margin-top: 5px;
      }
    }

    @include responsive('desktop') {
      gap: 60px;
      margin-bottom: 30px !important;
    }
  }

  &__btn-group {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    margin-top: 30px;

    @include responsive('desktop') {
      flex-direction: column;
    }
  }

  &__btn {
    border-radius: 12px !important;
    width: 100%;
    height: 64px;
    order: 1;

    &--disabled {
      background-color: var(--color-gray-light, #d9d9d9) !important;
      cursor: not-allowed !important;
    }

    @include responsive('desktop') {
      order: inherit;
    }
  }

  &__back-btn {
    color: var(--color-white-lightest, #ffffff) !important;
  }
}
