.custom-tooltip {
  --custom-tooltip-width: 220px;

  &__tooltip {
    border-radius: 24px !important;
    background-color: var(--color-white-lightest, #ffffff) !important;
    box-shadow: 0px 0px 10px 0px #0000001a;
    padding: 16px 24px !important;
    width: var(--custom-tooltip-width) !important;

    .MuiTooltip-arrow {
      color: var(--color-white-lightest, #ffffff) !important;
    }
  }
}
