.energy-tasks {
  background-color: var(--color-white-lightest);
  border-radius: 16px;
  padding: 16px;
  width: 320px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &__energy-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--color-yellow-base);
  }

  &__energy-icon {
    font-size: 24px;
  }

  &__close {
    color: var(--color-gray-dark);
    padding: 4px;
  }

  &__title {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-black);
    margin-bottom: 16px;
  }

  &__suggestions {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
  }

  &__task-button {
    height: 40px;
    border-radius: 16px;
    padding: 8px 12px;
    border: none;
    display: inline-flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    font-size: var(--font-size-s);
    transition: all 0.2s ease;

    &--primary {
      background-color: var(--color-purple-taro, #c9b8ff);
      color: var(--color-white-lightest);

      &:hover {
        background-color: var(--color-purple-taro-light);
      }
    }

    &--ai-scheduler {
      padding: 0;
      background: transparent;
      height: auto;

      &:hover {
        opacity: 0.8;
      }
    }

    &__icon {
      color: var(--color-white-lightest);
    }
  }

  &__task-icon {
    width: 14px;
    height: 16px;
    fill: currentColor;

    &--ai-scheduler {
      width: 126px;
      height: 40px;
      object-fit: contain;
    }
  }

  &__actions {
    display: flex;
    gap: 12px;
  }

  &__action-button {
    flex: 1;
    padding: 12px;
    border-radius: 24px;
    border: 1px solid var(--color-primary);
    background: transparent;
    color: var(--color-primary);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semi-bold);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: var(--color-purple-light);
    }
  }
}
