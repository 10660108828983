@import '../../../utils/styles/mixins/_responsive';

.connect-todos-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  @include responsive('desktop') {
    gap: 32px;
  }
}
