.task-detail-modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  &__content__item {
    display: flex;
    align-items: center;
    gap: 14px;

    p {
      font-size: var(--font-size-sm, 13px);
      color: var(--color-gray-base, #b5b5bc);
    }
  }
  &__project-dropdown {
    height: 400px;
  }
  &__icon {
    height: 20px !important;
    width: 20px !important;
    color: var(--color-gray-base, #b5b5bc) !important;
  }

  &__date {
    font-size: var(--font-size-sm, 13px);
    color: var(--color-black, #131314);
    cursor: pointer;
  }

  &__title-input {
    font-weight: var(--font-weight-semi-bold, 500);
    word-break: break-all;
    width: 100%;

    ::before {
      border: none !important;
    }

    .MuiInput-root {
      font-size: var(--font-size-l, 20px);
    }
  }

  &__desc-textarea {
    font-size: var(--font-size-m, 16px);
    width: 100%;

    ::before {
      border: none !important;
    }

    .MuiInput-root {
      font-size: var(--font-size-m, 16px);
    }
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 640px;
    border-radius: 24px;
    background-color: var(--color-white-lightest, #ffffff);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px 0;
    outline: none !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px 24px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 24px;
    border-bottom: solid 1px var(--color-gray-light, #d9d9d9);

    &__title {
      font-size: var(--font-size-l, 20px);
      font-weight: var(--font-weight-bold, 600);
    }

    &__close {
      cursor: pointer;
      color: var(--color-black, #131314) !important;
      height: 24px !important;
      width: 24px !important;
    }
  }

  &__project {
    font-size: var(--font-size-s, 14px);
    color: var(--color-black, #131314);
    font-weight: var(--font-weight-semi-bold, 500);
    border: 1px solid var(--color-black, #131314);
    border-radius: 8px;
    padding: 8px;
  }

  &__time-group {
    display: flex;
    flex-direction: row;
    gap: 4px;

    &__time {
      border-radius: 8px;
      border: solid 1px var(--color-gray-base, #b5b5bc);
      color: var(--color-gray-base, #b5b5bc);
      font-size: var(--font-size-s, 14px);
      padding: 8px;
      font-weight: var(--font-weight-semi-bold, 500);
    }

    &__phase {
      font-size: var(--font-size-s, 14px);
      color: white;
      padding: 8px;
      border-radius: 8px;
    }
  }

  &__title-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  &__details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__due-date-picker {
    display: flex;
    flex-direction: row;
    gap: 8px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    gap: 8px;
    margin-top: 99px;

    &__button {
      color: var(--color-white-lightest, #ffffff) !important;
      font-size: var(--font-size-xs, 12px) !important;
      font-weight: var(--font-weight-bold, 600) !important;
      border-radius: 8px !important;
      width: 61px;
      height: 28px;
      line-height: 10px !important;
      &.delete {
        color: var(--color-black, #131314) !important;
      }
      &.task-status {
        color: var(--color-black, #131314) !important;
        width: 130px;
        padding: 0 !important;
        &.MuiButton-root {
          &:disabled {
            color: var(--color-gray-base, #b5b5bc) !important;
          }
        }
      }
    }
  }
}
