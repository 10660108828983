.calendar-list-item {
  margin: 0 !important;
  justify-content: flex-start !important;
  height: 32px !important;
  gap: 8px;
  align-items: center;

  .MuiFormControlLabel-label {
    font-size: var(--font-size-s, 14px) !important;
    overflow-x: auto;
    flex: 1;
    white-space: nowrap;
    scrollbar-width: none;
  }

  .MuiCheckbox-root {
    padding: 0 !important;
  }
}
