.privacy-policy {
  color: var(--color-white-lightest, #ffffff);
  padding: 0 30px !important;
  text-align: center !important;

  &--highlight {
    color: var(--color-white-lightest, #ffffff);
    text-decoration: underline !important;
  }
}
