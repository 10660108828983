.account-settings {
  &__title {
    font-size: var(--font-size-m, 16px);
    color: #131314;
  }
  &__profile-img {
    height: 112px;
    width: 112px;
    border-radius: 4px;
    margin-top: 8px;
  }
  &__profile-img-placeholder {
    height: 112px;
    width: 112px;
    border-radius: 4px;
    background-color: #e8eaed;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: 800;
    color: #fff;
  }
}
