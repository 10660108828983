.core-actions {
  display: flex;
  padding-bottom: 24px;
  gap: 8px;

  &__action-container {
    position: relative;
  }

  &__energy-boost-discard {
    position: absolute;
    cursor: pointer;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: var(--color-purple-light, #ebe5fd);
    border-radius: 24px;
    font-weight: var(--font-weight-bold, 600);
    color: var(--color-primary, #680ddb);
  }

  &__action {
    height: 64px;
    background-color: var(--color-purple-light, #ebe5fd);
    border-radius: 24px;
    padding: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &--text-colorful {
      background: linear-gradient(135deg, #8560f9 0%, #5685bd 66.5%, #09c25a 100%) !important;
      -webkit-background-clip: text !important  ;
      background-clip: text !important;
      color: transparent !important;
      display: inline-block !important;
    }

    &__top-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }

    &__beta {
      background: linear-gradient(135deg, #8560f9 0%, #5685bd 66.5%, #09c25a 100%);
      color: var(--color-white, #ffffff);
      padding: 2px 4px;
      border-radius: 4px;
      font-size: var(--font-size-xxs, 10px);
      font-weight: var(--font-weight-bold, 600);
    }

    &__title {
      color: var(--color-primary, #680ddb);
      font-size: var(--font-size-m, 16px);
      font-weight: var(--font-weight-bold, 600);
      line-height: 16px;
    }
    &__description {
      color: var(--color-primary, #680ddb);
      font-size: var(--font-size-xs, 12px);
      margin-top: 4px;
    }
  }
}
