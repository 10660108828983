.project-filter {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer !important;
  width: 240px;
  height: 40px;
  transition-duration: 200ms;
  border-radius: 16px;
  padding: 0px 16px;
  border: 1px solid transparent;
  justify-content: flex-start !important;

  &:hover {
    background-color: var(--color-gray-purple, #f9f8fc);
  }

  &__open {
    border: 1px solid var(--color-black, #131314);
  }

  &__selected-title {
    font-size: var(--font-size-m, 16px);
    font-weight: var(--font-weight-semi-bold, 500);
  }

  &__dropdown {
    .MuiPaper-root {
      border-radius: 12px !important;
      width: 240px !important;
      box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a);
      margin-top: 5px;
      max-height: 208px;

      .MuiList-root {
        padding: 0 !important;
      }
    }

    &__item {
      gap: 12px !important;
      transition: all 0.3s ease;
      padding: 12px 16px !important;
      justify-content: flex-start !important;
      $self: &;

      &:hover {
        color: var(--color-black, #131314) !important;
        background-color: var(--color-gray-purple, #f9f8fc) !important;

        #{$self}__text {
          color: var(--color-black, #131314) !important;
        }

        #{$self}--all-label {
          color: var(--color-black, #131314) !important;
        }
      }
      &--selected {
        background-color: var(--color-gray-purple) !important;
      }

      &__icon {
        width: 16px;
        display: flex;
      }

      &--all-label {
        color: var(--color-gray-base, #b5b5bc);

        &--selected {
          color: var(--color-black, #131314) !important;
        }
      }

      &__text {
        color: var(--color-gray-base, #b5b5bc) !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &--selected {
          color: var(--color-black, #131314) !important;
        }
      }

      &.Mui-disabled {
        cursor: not-allowed !important;
      }
    }

    &__add-project {
      display: flex;
      width: 100%;
      justify-content: center !important;
    }
  }
}
