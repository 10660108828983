.energy-fluctuations-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 12px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
