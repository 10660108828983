.event-with-category {
  &__title-container {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &__title {
    font-weight: var(--font-weight-semi-bold, 500) !important;
  }
  &__icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  &__gray-text {
    color: var(--color-gray-dark);
  }
  &__score-container {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 4px;
    &__icon {
      height: 18px;
      width: 32px;
    }
  }
}
