.energy-popup {
  background-color: var(--color-white-lightest);
  border-radius: 16px;
  padding: 8px 16px;
  width: 280px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &__energy-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--color-yellow-base);
  }

  &__energy-icon {
    font-size: 24px;
  }

  &__energy-level {
    font-weight: var(--font-weight-bold) !important;
    font-size: 24px !important;
  }

  &__close {
    color: var(--color-gray-base) !important;
    padding: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-black);
    margin-bottom: 16px;
  }

  &__actions {
    display: flex;
    gap: 4px;
  }

  &__action-button {
    flex: 1;
    padding: 6px 4px;
    border-radius: 16px;
    border: 1px solid var(--color-primary);
    background: transparent;
    color: var(--color-primary);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semi-bold);
    cursor: pointer;
    transition: all 0.2s ease;
    height: 40px;

    &:hover {
      background-color: var(--color-purple-light);
    }
  }
}
