.circadian-chart-container {
  margin-top: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.event-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
