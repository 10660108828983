.connect-todos-dropdown {
  .MuiPaper-root {
    border-radius: 12px !important;
    padding: 0 24px 24px 24px;
    margin-top: 10px;
    width: 210px;
    box-shadow: 0px 0px 10px 0px #0000001a;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 29px;
    gap: 21px;
    font-weight: 700;
    font-size: 14px;

    p {
      margin: 0;
    }
  }

  &__close-button {
    padding: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &__icon {
      color: var(--color-black, #131314);
    }
  }

  &__item {
    padding: 8px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--font-size-s, 14px);
    background: #f9f8fc !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    justify-content: space-between !important;
    color: var(--color-primary, #680ddb) !important;
    border: 1px solid transparent !important;

    &--connected {
      border: var(--color-purple-taro, #c9b8ff) 1px solid !important;
      cursor: not-allowed !important;
    }

    &:hover {
      border: var(--color-purple-taro, #c9b8ff) 1px solid !important;
      background-color: var(--color-purple-taro-light, #c9b8ff33) !important;
    }
    &__text {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    border-radius: 2px;
  }
}
