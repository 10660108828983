.chart-tooltip {
  background: var(--color-white-lightest);
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px var(--color-black-light);

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__label {
    color: var(--color-gray-dark);
  }

  &__value {
    font-weight: var(--font-weight-semi-bold) !important;
    color: var(--color-black);
  }
}
