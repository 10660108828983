.event-energy-feedback {
  display: flex;
  gap: 10px;
  color: var(--color-black, #131314);
  align-items: flex-start;

  &__icon {
    color: var(--color-gray-base, #b5b5bc) !important;
    width: 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__title {
      font-size: var(--font-size-xs, 12px);
      color: var(--color-gray-base, #b5b5bc);
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
