.syncing-chip {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  &__text {
    font-size: var(--font-size-s, 14px);
  }
}
