.task-dashboard-container {
  width: 100%;
  padding-bottom: 16px;
  &--loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &__value {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-right: 26px;
    padding: 32px 0px;
  }
  &__percent {
    font-weight: var(--font-weight-bold, 600) !important;
    line-height: 32px !important;
  }
  &__title {
    color: var(--color-gray-medium, #5f6368) !important;
    line-height: 12px !important;
    white-space: nowrap;
  }
  &__progress {
    width: 100% !important;
    border: 1px solid var(--color-black) !important;
    height: 12px !important;
  }

  &__box {
    background-color: var(--color-white-lightest, #ffffff);
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
    border-radius: 24px;
    padding: 24px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
    &__title {
      font-weight: var(--font-weight-bold, 600) !important;
    }
    &--with-title {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
