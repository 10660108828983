.duration-field {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;

  &__text {
    font-size: var(--font-size-s, 14px) !important;
    font-weight: bold !important;
    color: var(--color-black, #131314);
    height: auto !important;
    background-color: transparent !important;
    padding: 0 !important;
    min-width: auto !important;
    letter-spacing: -0.05em !important;
  }

  &__input {
    &__edit {
      color: var(--color-gray-light, #d9d9d9) !important;
      width: 16px !important;
      height: 16px !important;
    }
  }

  &__menu {
    .MuiPaper-root {
      border-radius: 12px !important;
      max-height: 100px;
      box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a);
      margin-top: 5px;
      height: 200px !important;
      max-height: 200px !important;
      overflow: hidden !important;
      padding: 10px !important;

      .MuiList-root {
        padding: 0 !important;
      }
    }

    &__list {
      display: flex;
      flex-direction: row;
      gap: 10px;

      &__items {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 200px;

        &::-webkit-scrollbar {
          width: 2px !important;
        }

        &--wrapper {
          display: flex;
          flex-direction: row;
          gap: 4px 8px;
        }

        &__item {
          padding: 4px !important;
          justify-content: center !important;
          border-radius: 6px !important;

          &--selected {
            background-color: var(--color-purple-light, #ebe5fd) !important;
          }

          &--disabled {
            color: var(--color-gray-light, #d9d9d9) !important;
          }
        }

        &__format {
          color: var(--color-gray-base, #b5b5bc) !important;
          font-size: var(--font-size-s, 14px) !important;
          padding-top: 8px;
        }
      }
    }
  }
}
