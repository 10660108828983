.energy-pref-setting-select {
  &__select {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-primary, #680ddb) !important;
      }
    }

    .MuiInputBase-root {
      border-radius: 6px !important;
    }

    .MuiSelect-select {
      font-size: var(--font-size-s, 14px) !important;
    }

    .MuiPaper-root {
      border-radius: 12px !important;
      width: 240px !important;
      box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a);
      margin-top: 5px;
      max-height: 208px;

      .MuiList-root {
        padding: 0 !important;
      }
    }
  }

  &__menu {
    border-radius: 12px !important;
    width: 160px !important;
    box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a) !important;
    margin-top: 5px;
    max-height: 208px !important;

    .MuiList-root {
      padding: 0 !important;
    }

    &__item {
      font-size: var(--font-size-s, 14px) !important;
    }
  }

  &__label {
    font-size: var(--font-size-s, 14px) !important;
    color: var(--color-primary, #680ddb) !important;
  }
}
