@import '../../utils/styles/mixins/_responsive';

.auth-page {
  width: 100%;

  &__left-section {
    position: relative;

    &__asset {
      width: 100%;
      z-index: 2;
      position: relative;
    }

    &__wave {
      position: absolute;
      left: 0;
      width: 110%;
      top: 45%;
      transform: translate(-5%, -45%);
      z-index: 1;
    }
  }

  &__right-section-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    @include responsive('desktop') {
      display: block;
    }
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    gap: 40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 44px 0px;
    text-align: center;

    &__title {
      color: var(--color-white-lightest, #ffffff);
      font-family: var(--font-family-nunito) !important;
    }

    &__description {
      line-height: 30px !important;
      font-weight: 500 !important;
      color: var(--color-white-lightest, #ffffff);
      margin-top: 30px !important;
    }

    &__btn {
      border-radius: 12px !important;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      width: 100%;
      height: 64px;
      margin-top: 30px !important;

      @include responsive('desktop') {
        margin-top: auto !important;
      }
    }

    @include responsive('desktop') {
      width: 400px;
      align-items: flex-start;
      text-align: left;

      &__description {
        line-height: 36px !important;
        margin-top: 60px !important;
      }
    }
  }

  &__privacy-policy {
    margin-top: auto;
    text-align: center;

    @include responsive('desktop') {
      margin-top: 25px;
    }
  }
}
