.kanban-list-filter {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer !important;
  height: 32px;
  transition-duration: 200ms;
  border-radius: 12px;
  padding: 0px 12px;
  border: 1px solid transparent;
  justify-content: flex-start !important;

  .MuiSvgIcon-root {
    width: 20px !important;
    height: 20px !important;
    color: var(--color-black, #131314) !important;
  }

  &:hover {
    background-color: var(--color-gray-purple, #f9f8fc);
  }

  &__open {
    border: 1px solid var(--color-black, #131314);
  }

  &__selected-title {
    font-size: var(--font-size-s, 14px);
    font-weight: var(--font-weight-semi-bold, 500);
  }

  &__dropdown {
    .MuiPaper-root {
      border-radius: 12px !important;
      width: 200px !important;
      box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a);
      margin-top: 5px;
      max-height: 208px;

      .MuiList-root {
        padding: 0 !important;
      }
    }

    &__item {
      gap: 8px !important;
      transition: all 0.3s ease;
      padding: 8px 12px !important;
      justify-content: flex-start !important;
      $self: &;
      display: flex;
      flex-direction: row;
      gap: 4px;

      &:hover {
        color: var(--color-black, #131314) !important;
        background-color: var(--color-gray-purple, #f9f8fc) !important;

        #{$self}__text {
          color: var(--color-black, #131314) !important;
        }
      }

      &--selected {
        color: var(--color-black, #131314) !important;
        background-color: var(--color-gray-purple, #f9f8fc) !important;
      }

      &__icon {
        width: 20px !important;
        height: 20px !important;
        color: var(--color-black, #131314) !important;
      }

      &__text {
        font-size: var(--font-size-s, 14px) !important;
        color: var(--color-black, #131314) !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
