.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(249, 248, 252, 0.5); //--color-gray-purple: #f9f8fc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
  z-index: 1000;

  &__logo {
    width: 80px;
  }
}
