.heatmap-bar-chart {
  position: relative;
  margin-right: 1px;
  margin-left: 1px;

  &--default {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  }

  &--thin,
  &--thinner {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  &__rect {
    cursor: pointer;
  }

  &__indicator {
    position: absolute;
    padding: 0;
    transform: translate(-15%, -50%);
    width: 24px;
    height: 24px;
    background-color: var(--color-primary, #680ddb);
    border-radius: 50%;
    border: solid 5px var(--color-white-lightest, #ffffff);
  }
}
