.task-filter {
  &--open {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &__menu {
    border-radius: 12px !important;
    width: 240px !important;
    box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a) !important;
    margin-top: 5px;
    max-height: 300px;
    padding: 0 !important;

    .MuiList-root {
      padding: 0 !important;
    }
  }

  &__content {
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
      margin: 0 !important;
      justify-content: space-between !important;
      height: 40px !important;

      .MuiFormControlLabel-label {
        font-size: var(--font-size-s, 14px) !important;
      }

      .MuiCheckbox-root {
        padding: 0 !important;
      }
    }
  }

  &__accordion {
    box-shadow: none !important;
    margin: 0 !important;

    .MuiCollapse-root {
      overflow: auto !important;
      scrollbar-width: none;
    }

    .MuiAccordionDetails-root {
      padding: 0 !important;
    }

    &__summary {
      min-height: 32px !important;
      padding: 0 !important;

      .MuiAccordionSummary-content {
        margin: 8px 0 !important;
      }

      &__text {
        font-size: var(--font-size-s, 14px) !important;
      }
    }

    &__content {
      max-height: 300px;
      overflow-y: auto;
      flex-wrap: nowrap !important;

      &__label {
        display: flex;
        align-items: center;
        gap: 8px;

        &--wrapper {
          justify-content: space-between !important;
          margin: 0 !important;
          border-radius: 6px !important;
          padding-left: 8px !important;

          &:hover {
            background: var(--color-gray-purple, #f9f8fc);
          }
        }

        &__color {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          flex-shrink: 0;
        }

        &__text {
          font-size: var(--font-size-xs, 12px);
          color: var(--color-black, #131314);
        }
      }
    }
  }
}
