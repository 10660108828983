.feature-feedback-dialog {
  .MuiPaper-root {
    width: 800px !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__description {
      font-size: var(--font-size-s, 14px);
      line-height: 1.3rem;
    }

    &__answer-group {
      margin-top: 30px;
    }
  }

  &__upvote {
    display: flex;
    gap: 10px;
    align-items: center;

    &__btn {
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: solid 1px var(--color-primary, #680ddb);
      background-color: var(--color-white-lightest, #ffffff);
      cursor: pointer;

      &--upvoted {
        background-color: var(--color-primary, #680ddb);
      }

      &__icon {
        color: var(--color-primary, #680ddb);

        &--upvoted {
          color: var(--color-white-lightest, #ffffff);
        }
      }
    }

    &__label {
      font-size: var(--font-size-s, 14px);
    }
  }
}
