.empty-kanban-card {
  min-height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  word-break: break-word;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  background-color: var(--color-white-lightest, #ffffff);

  &__title {
    width: 100%;
    font-size: var(--font-size-s, 14px);
    margin: auto;
    text-align: center;
  }
}
