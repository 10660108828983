.core-action-tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__title {
    font-size: var(--font-size-s, 14px);
    font-weight: var(--font-weight-bold, 600);
    color: var(--color-black, #131314);
  }

  &__description {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-gray-dark, #7c7c7c);
    line-height: 1.2rem;
  }
}
