.calendars-list {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__accordion {
    &::before {
      height: 0 !important;
    }

    &.Mui-expanded {
      margin: 0 !important;
    }

    .MuiAccordionDetails-root {
      padding: 0 !important;
    }
    .MuiAccordionSummary-root {
      padding: 0 !important;
      min-height: 32px !important;

      &.Mui-expanded {
        min-height: auto !important;
      }

      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
    }

    &__title {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      font-size: var(--font-size-sm, 13px);
      color: var(--color-black, #131314);
      padding: 0px;

      &__icon {
        width: 20px !important;
        height: 20px !important;
        font-size: 0.5rem !important;
        background-color: var(--color-purple-light, #ebe5fd) !important;
        color: var(--color-black, #131314) !important;
      }

      &__email {
        flex: 1;
        font-size: var(--font-size-s, 14px);
        flex: 1;
        white-space: nowrap;
        scrollbar-width: none;
        overflow-x: auto;
        width: 0px; // make the flex work
        padding-bottom: 2px; //centralize
      }

      &__error {
        color: var(--color-red-base, #f34720);
        cursor: pointer;
      }

      &__more {
        width: 20px !important;
        color: var(--color-black, #131314) !important;
      }
    }
  }

  &__item-group {
    display: flex;
    flex-direction: column;
    padding-right: 6px;
  }

  &__loading {
    margin: auto;
  }
}
