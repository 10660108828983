.phase-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  border-radius: 10px;
  width: 100%;
  padding: 8px;

  &__title {
    font-size: var(--font-size-s, 14px);
    font-weight: var(--font-weight-semi-bold, 500);
  }

  &__time {
    font-size: var(--font-size-sm, 13px);
    margin-left: 18px;
  }
}
