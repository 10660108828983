.ai-scheduler-save-all-modal {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  &__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white-lightest, #ffffff);
    padding: 10px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  &__title {
    font-size: var(--font-size-s, 14px);
    color: var(--color-black, #131314);
  }

  &__description {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    color: var(--color-primary, #680ddb);
    font-size: var(--font-size-xs, 12px);
  }

  &__right {
    height: 45px;
    object-fit: contain;
    cursor: pointer;
  }
}
