//TODO: use absolute path
@import '../../../utils/styles/mixins/_responsive';

.calendly-schedule {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;

  &__header {
    width: 100%;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    height: 100%;

    .calendly-inline-widget {
      width: 100%;
      flex: 1;
      min-height: 630px;
    }

    @include responsive('desktop') {
      flex-direction: row;
    }

    &__phase {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      width: 100%;

      @include responsive('desktop') {
        margin-right: 50px;
        margin-top: 60px;
        width: 250px;
      }

      &__title {
        font-size: var(--font-size-l, 20px);
        font-weight: var(--font-weight-bold, 600);
      }

      &__list {
        margin-top: 20px;
      }
    }
  }
}
