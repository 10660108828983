.week-dates-switcher {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  &__date {
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-base, #b5b5bc);
    position: relative;
    cursor: pointer;
    gap: 2px;
    
    &__day {
      line-height: 14px !important;
    }
    &--active {
      background-color: var(--color-primary, #680ddb);
      color: var(--color-white-lightest, #ffffff);
      border-radius: 16px;
    }

    &__indicator {
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: var(--color-purple-taro, #c9b8ff);
      margin-top: 1px;
      position: absolute;
      bottom: 1px;
    }
  }
}
