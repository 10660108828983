.default-pref-edit-item {
  width: 100%;
  background-color: var(--color-white-lightest, #ffffff);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 10px;
  &__top-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: var(--font-size-s, 14px);
    color: var(--color-gray-dark, #7c7c7c);
    font-weight: bold;
  }

  &__switch {
  }

  &__bottom-group {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  &__time-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;

    &__title {
      font-size: var(--font-size-xs, 12px);
      color: var(--color-gray-base, #b5b5bc);
      font-weight: var(--font-weight-semi-bold, 500);
    }

    &__value {
      &__popper {
        .MuiPaper-root {
          border-radius: 12px !important;
          max-height: 100px;
          box-shadow: 0px 0px 10px 0px var(--color-black-light, #0000001a);
          margin-top: 5px;
          height: 200px !important;
          max-height: 200px !important;
          overflow: hidden !important;
          padding: 10px !important;

          .MuiMenuItem-root {
            border-radius: 6px !important;
            font-size: var(--font-size-s, 14px) !important;

            &.Mui-selected {
              background-color: var(--color-purple-light, #ebe5fd) !important;
              color: var(--color-black, #131314) !important;
            }
          }
        }
      }

      .MuiInputBase-root {
        font-size: var(--font-size-s, 14px) !important;
        font-weight: bold !important;

        .MuiInputBase-input {
          padding: 0 !important;
          max-width: 70px !important;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
      .MuiInputAdornment-root {
        margin-left: 0 !important;
      }
      .MuiButtonBase-root {
        height: auto !important;
        background-color: transparent !important;
        color: var(--color-gray-light, #d9d9d9) !important;
        padding: 0 !important;

        .MuiSvgIcon-root {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
