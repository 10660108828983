.custom-time-gutter {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding-left: 20px;

  &__zone-labels {
    width: 50px;
    position: absolute;
    left: 0px;
  }

  &__heatmap-container {
    position: relative;
  }

  &__indicator {
    position: absolute;
    padding: 0;
    transform: translate(-15%, -50%);
    width: 24px;
    height: 24px;
    background-color: var(--color-primary, #680ddb);
    border-radius: 50%;
    border: solid 5px var(--color-white-lightest, #ffffff);
    pointer-events: auto;
  }
}
