.reference-link {
  cursor: pointer;
  padding: 3px 10px;
  border: solid 0.5px var(--color-primary, #680ddb);
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 160px;

  &__list {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none;
  }

  &__icon {
    color: var(--color-primary, #680ddb);
    width: 6px;
  }

  &__text {
    color: var(--color-primary, #680ddb);
    font-size: var(--font-size-xxs, 10px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
