.sleep-bar-info-container {
  display: flex;
  flex-direction: column;
  &__edit-icon {
    margin-bottom: 16px;
    margin-left: auto;
    cursor: pointer;
  }
  &__stage-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;

    &__time {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }

  &__sleep-stage-bullet-container {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--color-gray-base, #b5b5bc);
    &__bullet {
      height: 5px;
      width: 5px;
      border-radius: 50%;
    }
  }
}
