.circadian-dashboard {
  height: 100%;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 128px);
  }

  &__energy {
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    margin-top: 16px;
    gap: 16px;
  }
  &__peak-dips {
    margin-top: 16px;
    &--weekly {
      display: grid;
      grid-template-columns: 1.7fr 1fr;
      margin-top: 16px;
      gap: 16px;
    }
  }

  &__box {
    background-color: var(--color-white-lightest, #ffffff);
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
    border-radius: 24px;
    padding: 24px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--weekly {
      padding: 16px !important;
    }
  }
  .loader {
    margin-top: 16px !important;
  }
}
