.not-supported {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: var(--color-white-lightest, #ffffff);
  gap: 80px;

  &__logo {
    height: 160px;
    width: auto;
    object-fit: contain;
  }

  &__icon {
    font-size: 60px !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    margin-bottom: 100px;

    &__image {
      width: 30px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: var(--font-size-xl, 24px);
      text-align: center;
      font-weight: 600;
    }

    &__btn-group {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      &__btn {
        width: 350px;
        height: 56px;
        border-radius: 8px !important;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    position: absolute;
    bottom: 50px;

    a {
      color: var(--color-gray-dark, #7c7c7c);
      text-decoration: none;
    }
  }
}
