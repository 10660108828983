.daily-analysis-modal {
  padding: 40px;
  display: flex;
  gap: 16px;

  &__loader {
    width: 100%;
    height: calc(100% - 83px); // 83px is the height of the header
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__energy-container {
    height: 360px;
    position: relative;
    width: 440px;
    border-radius: 24px;
    background-color: var(--color-white, #ffffff);
    border: solid 2px var(--color-black-lightest, #0000000d);
    overflow: hidden;

    &__gauge {
      margin: auto;
    }

    &__logo {
      height: 34px;
      width: 34px;
      margin-top: 12px;
      margin-left: 25px;
    }

    &__bg {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
    }

    &__title {
      color: var(--color-gray-base, #b5b5bc);
      font-size: var(--font-size-s, 14px);
      text-align: center;
      transform: translateY(-24px);
    }

    &__guidance-text {
      color: var(--color-black, #131314);
      font-weight: var(--font-weight-bold, 700);
      font-size: var(--font-size-l, 20px);
      text-align: center;
    }

    &__energy-stats {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 18px;
      width: 100%;
      padding: 0 20px;
    }

    &__stat-card {
      background-color: var(--color-white-lightest, #ffffff);
      width: 100%;
      border-radius: 16px;
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      position: relative;
      z-index: 2;

      &-value {
        font-weight: var(--font-weight-bold, 700);
        font-size: var(--font-size-ml, 18px);
      }

      &-label {
        color: var(--color-gray-base, #b5b5bc);
        font-size: var(--font-size-xs, 12px);
      }
    }
  }
  &__sleep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
