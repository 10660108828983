.sleep-analysis {
  border: 2px solid var(--color-black-lightest, #0000000d);
  padding: 16px;
  width: 264px;
  border-radius: 24px;
  height: max-content;

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: var(--color-black, #131314);
    font-weight: var(--font-weight-bold, 600);
    font-size: var(--font-size-m, 16px);
  }

  &__chevron-right {
    cursor: pointer;
    color: var(--color-black, #131314);
  }

  &__chronotype-hours {
    margin-top: var(--font-size-m, 16px);
    display: flex;
    align-items: center;
    gap: var(--font-size-s, 14px);
    color: var(--color-black, #131314);

    &__title {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__icon {
      color: var(--color-gray-base, #b5b5bc);
      height: 20px !important;
      width: 20px !important;
    }
  }

  &__divider {
    margin: var(--font-size-m, 16px) 0;
    height: 1px;
    width: 100%;
    background-color: var(--color-gray-lightest, #e8eaed);
    border-radius: 9999px;
  }

  &__sleep-duration {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    &-info {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
