.lifestack-title {
  text-align: center;
  width: 320px;

  &__text {
    font-size: var(--font-size-xl, 24px);
    font-family: 'Roboto', sans-serif;
    font-weight: 600;

    &--highlight {
      color: var(--color-secondary, #09c25a);
    }
  }
}
