.event-categories {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;

  &__icon {
    color: var(--color-gray-base, #b5b5bc) !important;
    width: 32px !important;
  }
  &__form-control {
    width: 100%;
  }
  &__select {
    &.MuiOutlinedInput-root {
      padding: 0 !important;
      border-radius: 8px !important;

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid transparent !important;
      }
    }

    &.event-categories__select--open {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--color-gray-lightest) !important;
        border-radius: 8px 8px 0 0 !important;
      }
    }

    .MuiSelect-select {
      padding: 10px 8px !important;
    }
  }

  &__menu-item {
    padding: 8px !important;
    color: var(--color-gray-base, #b5b5bc) !important;
    display: flex;
    align-items: center;
    gap: 2px;
    &.Mui-selected {
      background-color: transparent !important;
      color: var(--color-gray-medium) !important;
    }
  }
  &__menu-paper {
    box-shadow: none !important;
    max-height: 216px !important;
    border: 1px solid var(--color-gray-lightest) !important;
    border-radius: 0px 0px 8px 8px !important;
    border-top: 0 !important;
  }
}
