.energy-event-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 4px;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;

    &__title {
      font-weight: var(--font-weight-semi-bold, 500) !important;
      font-size: var(--font-size-xxs, 10px) !important;
      width: 100%;
      text-align: right;
    }
  }
}
