.wearable-button {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  cursor: pointer;
  color: var(--color-gray-medium, #5f6368);
  transition: all 0.3s ease;
  font-size: 12px;

  &--connected {
    border: 1px solid var(--color-purple-taro, #c9b8ff);
    background-color: var(--color-purple-taro-light, #c9b8ff33);
    cursor: not-allowed !important;
  }

  &--disconnected {
    border: 1px solid var(--color-gray-purple, #f9f8fc);
    background-color: var(--color-gray-purple, #f9f8fc);
    &:hover {
      border: 1px solid var(--color-primary, #680ddb);
    }
  }

  &--error {
    cursor: pointer !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 89px;
    gap: 4px;

    &__name {
      font-size: var(--font-size-xs, 12px);
      word-break: break-word;
      text-align: left;
    }
  }

  &__error-icon {
    color: var(--color-red-base, #f34720);
    font-size: var(--font-size-s, 14px) !important;
  }

  &__status {
    color: var(--color-primary, #680ddb);
    font-size: var(--font-size-sm, 13px);
    min-width: 63px;

    &--error {
      color: var(--color-red-base, #f34720);
    }
  }

  &__icon {
    border-radius: 4px;
    width: 20px;
    min-width: 20px;
  }
}
