.drag-and-drop-practice {
  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(15px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: var(--color-black-mid, #0000004d);
  }

  &__modal {
    width: 996px;
    background-color: var(--color-white-lightest);
    height: 720px;
    border-radius: 40px;
    padding: 48px 90px;
    overflow: hidden;
    position: relative;

    &__stepper-container {
      display: flex;
      gap: 8px;
      justify-content: center;
      &__stepper {
        height: 10px;
        width: 10px;
        border: 1px solid var(--color-primary, #680ddb);
        border-radius: 50%;
        &--active {
          background-color: var(--color-primary, #680ddb);
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    &-text {
      color: var(--color-black);
      font-size: var(--font-size-xl);
      white-space: nowrap;
    }
  }

  &__content {
    background-color: var(--color-white-lightest, #ffffff);
    padding: 8px;
    border-radius: 18px;
    width: 758px;
    height: 520px;
    display: flex;
    gap: 8px;
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
    margin: 19px auto;
    position: relative;

    &__practice-calendar {
      position: absolute;
      right: 0;
      top: 0;
      width: 400px;
      border-left: 1px solid var(--color-gray-lightest, #e8eaed);
    }
  }

  &__skip {
    position: absolute;
    bottom: 10px;
    right: 35px;
    color: var(--color-gray-dark, #7c7c7c);
    font-size: var(--font-size-m, 16px);
    cursor: pointer;
  }
}

.practice-list {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-purple, #f9f8fc);
  border-radius: 18px;
  height: 100%;
  overflow-y: hidden;
  width: 317px;
  margin-right: -8px; // to adjust the extra padding on the right of the list

  &__header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;

    &__day {
      font-size: var(--font-size-l, 20px);
      color: var(--color-black, #131314);
      font-weight: var(--font-weight-bold, 600);
    }

    &__date {
      font-size: var(--font-size-s, 14px);
      font-weight: var(--font-weight-semi-bold, 500);
      color: var(--color-gray-base, #b5b5bc);
    }
  }

  &__item-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding: 3px;
  }
}
