.daily-weekly-switcher {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--color-purple-taro, #c9b8ff);
  border-radius: 100px;
  height: 48px;
  color: var(--color-white-lightest, #ffffff);
  padding: 4px;

  &__title {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--active {
      background-color: var(--color-white-lightest, #ffffff);
      color: var(--color-primary, #680ddb);
    }
  }
}
