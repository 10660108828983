.settings-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--color-white-lightest, #ffffff);
  border-radius: 0 0 24px 24px;
  gap: 6px;

  .MuiAvatar-root {
    border-radius: 8px !important;
    height: 40px;
    width: 40px;
  }

  &__name {
    font-size: var(--font-size-ml, 18px);
    width: 200px;
    flex: 1;
    white-space: nowrap;
    scrollbar-width: none;
    overflow-x: auto;
    font-weight: var(--font-weight-semi-bold, 500);
  }

  &__icon {
    cursor: pointer;
    padding-right: 6px;
  }
}
