.data-sleep-dashboard {
  &__box {
    background-color: var(--color-white-lightest, #ffffff);
    box-shadow: 0px -4px 24px 0px var(--color-black-lightest, #0000000d);
    border-radius: 24px;
    padding: 24px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    overflow: hidden;
    &__no-chrono {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__pie-chart {
    transform: translateX(100px);
  }
  &__title {
    font-weight: var(--font-weight-bold, 600) !important;
    line-height: 32px !important;
  }
  &__subtitle {
    color: var(--color-gray-medium, #5f6368) !important;
    margin-top: 4px !important;
    line-height: 12px !important;
  }
  &__chrono-container {
    font-weight: var(--font-weight-bold, 600);
    display: flex;
    gap: 2px;
    justify-content: start;
    align-items: center;
    width: 100%;

    &__title {
      display: flex;
      gap: 2px;
      align-items: center;
    }
    &__has-no-chrono-description {
      color: var(--color-gray-medium, #5f6368) !important;
      margin-left: 27px !important;
      margin-top: 4px !important;
    }
    &__diagnose-button {
      border-radius: 8px !important;
    }
  }

  &__chronotype__tooltip {
    &__icon {
      color: var(--color-gray-base, #b5b5bc);
      height: 20px !important;
      width: 20px !important;
      margin-left: 10px;
    }
  }
}
