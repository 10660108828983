.peak-dips-values-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  &__container {
    margin-top: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    &--weekly {
      display: flex;
      flex-direction: column;
    }
    &__item {
      border-radius: 10px;
      padding: 10px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        font-weight: var(--font-weight-semi-bold, 500) !important;
      }
      &__subtitle {
        line-height: 12px !important;
        margin-top: 5px;
      }
      &__timing {
        background-color: var(--color-white-lightest, #ffffff);
        padding: 4px 8px;
        border-radius: 6px;
        &__diff {
          color: var(--color-gray-dark, #7c7c7c);
        }
      }
    }
  }
  &__title {
    font-weight: var(--font-weight-med-bold, 700) !important;
  }
}
