.app-download-switcher {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 140px;

  &__image {
    width: 100%;
  }

  &__button-group {
    display: flex;
    justify-content: center;
    width: 100%;

    &__button {
      font-size: var(--font-size-m, 14px);
      border-radius: 12px;
      flex: 1;
      padding: 10px 0;
      text-align: center;
      cursor: pointer;

      &--active {
        background-color: var(--color-gray-lightest, #e8eaed);
      }
    }
  }
}
