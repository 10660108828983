.core-action-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiBackdrop-root {
    background-color: var(--color-black-subtle, #13131466) !important;
    backdrop-filter: blur(10px) !important;
  }
  &__container {
    background-color: var(--color-white-lightest, #ffffff);
    border-radius: 24px;
    width: 800px;
    height: 560px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 24px 24px 24px;
    border-bottom: solid 1px var(--color-gray-light, #d9d9d9);

    &__title-group {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &__title {
      font-size: var(--font-size-l, 20px);
      font-weight: var(--font-weight-med-bold, 700);
    }

    &__info {
      cursor: pointer;
      color: var(--color-gray-base, #b5b5bc);
      &__tooltip {
        --custom-tooltip-width: 300px;
      }
    }

    &__close {
      cursor: pointer;
    }
  }

  &__button {
    cursor: pointer;
    background-color: var(--color-primary, #680ddb) !important;
    border-radius: 50px !important;
    height: 56px !important;
    font-size: var(--font-size-ml, 18px) !important;
    line-height: 18px !important;
    color: var(--color-white-lightest, #ffffff) !important;
    font-weight: var(--font-weight-semi-bold, 500) !important;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    margin-left: auto;
    font-size: var(--font-size-xs, 12px);
    color: var(--color-gray-base, #b5b5bc);
  }
}
