.calendly-configure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  gap: 30px;

  &__header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    &__logo {
      width: 250px;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;

    &__title {
      font-size: var(--font-size-l, 20px);
    }

    &__field {
      width: 600px;
    }
  }
}
