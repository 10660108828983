.about-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
 
  &__item {
    cursor: pointer;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: '4px';
    color: var(--color-primary, #680ddb);
    &:hover {
      color: var(--color-primary-dark, #3e0ca4);
    }
    &__icon {
      margin-top: 2px;
      margin-left: 4px;
    }
    &--disabled {
      cursor: not-allowed;
      color: var(--color-gray-base, #b5b5bc);
      &:hover {
        color: var(--color-gray-base, #b5b5bc);
      }
    }
  }
}
