.subscription-timeline {
  --subscription-timeline-text-color: var(--color-white-lightest, #ffffff);

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &__left-group {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  &__title-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__action {
      font-size: var(--font-size-xs, 12px);
      color: var(--subscription-timeline-text-color);
    }
  }

  &__right-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }

  &__price {
    font-size: var(--font-size-xs, 12px);
    font-weight: var(--font-weight-bold, 600);
    color: var(--subscription-timeline-text-color);
    display: flex;
    align-items: center;

    &--red {
      color: var(--color-red-base, #f34720);
      font-weight: 400;
    }
  }
}

.vertical-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__line {
    width: 1px;
    flex: 3;
    background-color: var(--subscription-timeline-text-color);
  }

  &__circle {
    width: 5px;
    height: 5px;
    border-radius: 4px;
    background-color: var(--subscription-timeline-text-color);
  }
}
