.calendar-timeline {
  --calendar-timeline-padding: 16px;

  flex: 1;
  padding-left: var(--calendar-timeline-padding);

  &__container {
    height: 100%;
    overflow-y: auto;
  }

  &__aischeduler-save-all-modal {
    position: absolute;
    right: var(--calendar-timeline-padding) + var(--view-toggle-width, 48px);
    bottom: 22px; // padding bottom
  }

  .rbc-time-content {
    border-top: none;
  }
  .rbc-day-slot {
    background-color: transparent !important;
  }

  .rbc-time-column {
    min-height: auto !important;
  }

  .rbc-timeslot-group {
    height: 36px !important; //HALF_HOUR_BLOCK_HEIGHT
    min-height: 36px !important;
  }

  .rbc-events-container {
    margin-right: 0 !important;
  }

  .rbc-event-content {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  .rbc-background-event {
    cursor: unset !important;

    &:focus {
      outline: none !important;
    }
  }

  .rbc-addons-dnd-resize-ns-icon {
    width: 100% !important;
  }

  .rbc-time-slot,
  .rbc-time-view {
    border: none !important;
  }

  .rbc-time-header,
  .rbc-event-label {
    // Hide header and event's time-label
    display: none;
  }

  .rbc-label {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-gray-dark, #7c7c7c);
  }

  .rbc-time-gutter {
    .rbc-timeslot-group {
      border: none !important;
      margin-top: -13px; // centerlize the lable
    }
  }
}
