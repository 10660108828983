.zone-label-timeline {
  height: 100%;

  &__item {
    position: absolute;
    cursor: pointer;
    border-right: none !important;
    border-radius: 12px 0 0 12px;
    width: 90px !important;
    &--24h {
      width: 70px !important;
    }

    &__label {
      display: flex;
      flex-direction: row;
      gap: 2px;
      align-items: center;
      justify-content: flex-end;
      font-size: var(--font-size-xs, 12px);
      font-weight: var(--font-weight-semi-bold, 500);
      transform: rotate(-90deg);
      width: 120px;
      position: absolute;
      left: -50px;
      top: 60px;

      &__help {
        font-size: 1rem !important;
        color: var(--color-gray-base, #b5b5bc);
      }
    }
  }
}
