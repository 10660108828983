.mobile-install-dialog {
  &__content {
    display: flex;
    flex-direction: column !important;
    gap: 20px !important;
    padding: 20px 60px 0 60px !important;
    text-align: center !important;

    &__image {
      margin: auto;
      text-align: center;
    }

    &__store-links {
      display: flex;
      gap: 40px;
      justify-content: center;
      align-items: center;

      &__image {
        width: 100px;
      }
    }
  }
}
