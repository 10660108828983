.todo-list-more-dropdown {
  .MuiPaper-root {
    border-radius: 12px !important;
    padding: 12px;
    min-width: 200px;
    box-shadow: 0px 0px 10px 0px #0000001a;
  }

  .MuiList-root {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 !important;
  }

  &__option {
    display: flex;
    align-items: center;
    background: var(--color-gray-purple, #f9f8fc) !important;
    border-radius: 8px !important;
    gap: 4px;
    color: var(--color-primary, #680ddb) !important;
    border: 1px solid transparent !important;

    &:hover {
      border: var(--color-purple-taro, #c9b8ff) 1px solid !important;
    }

    &__title {
      font-size: var(--font-size-s, 14px);
      color: var(--color-primary, #680ddb) !important;
    }
  }
}
