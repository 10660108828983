.kanban-task-card {
  box-shadow: 0px 0px 5px var(--color-black-light, #0000001a);
  border-radius: 8px;
  cursor: grab;
  word-break: break-word;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--color-white-lightest, #ffffff);

  &__top-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__time {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }

    &__overdue {
      color: var(--color-red-base, #f34720);
      font-size: var(--font-size-xxs, 10px);
      font-weight: var(--font-weight-semi-bold, 500);
    }
  }

  &__time {
    border-radius: 4px;
    border: solid 1px var(--color-gray-base, #b5b5bc);
    color: var(--color-gray-base, #b5b5bc);
    font-size: var(--font-size-xxs, 10px);
    padding: 4px;
    font-weight: var(--font-weight-semi-bold, 500);
  }

  &__phase {
    font-size: var(--font-size-xxs, 10px);
    color: white;
    padding: 4px;
    border-radius: 4px;
  }

  &__title {
    width: 100%;
    font-size: var(--font-size-s, 14px);
    font-weight: var(--font-weight-semi-bold, 500);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--done {
      text-decoration: line-through;
    }
  }

  &__bottom-group {
    margin: 4px 0px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &__status {
      cursor: pointer !important;
      justify-content: center;
      display: flex;

      &__icon {
        height: 18px !important;
        width: 18px !important;
      }
    }
  }

  &__project {
    padding: 4px;
    max-width: 70px;
    display: flex;
    gap: 2px;
    align-items: center;

    &__icon {
      width: 9px;
      display: flex;
    }

    &__text {
      font-size: var(--font-size-xxs, 10px);
      color: var(--color-gray-dark, #7c7c7c);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__labels {
    margin-top: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;

    &__item {
      padding: 2px 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;

      border-radius: 4px;

      &__icon {
        height: 10px !important;
        width: 10px !important;
        color: var(--color-gray-dark, #7c7c7c) !important;
        padding-top: 1px !important;
      }

      &__name {
        font-size: var(--font-size-xxs, 10px);
        color: var(--color-black, #131313) !important;
      }
    }
  }
}
