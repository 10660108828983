.settings-action-list {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 240px;
  padding: 8px 12px 16px 12px;
  overflow-y: auto;

  &__section-title {
    color: #b5b5bc;
    font-size: 13px;
    padding: 9.5px 12px;
    margin-top: 8px;
  }
  &__item {
    display: flex;
    flex-direction: row;
    padding: 10px 12px;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    color: #5f6368;
    &__title {
      font-size: 13px;
    }

    &__icon {
      width: 24px;
      height: 24px;
    }

    &--selected {
      color: var(--color-primary, #680ddb);
      background-color: #c9b8ff80;
    }
    &:not(.settings-action-list__item--selected):hover {
      background-color: var(--color-gray-purple, #f9f8fc);
    }
  }
}
