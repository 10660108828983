.activity-save-menu {
  .MuiPaper-root {
    position: absolute;
    bottom: 0;
    right: var(--calendar-width, 410px);
    width: 360px !important;
    border-radius: 24px;
    gap: 0px;
    box-shadow: 0px 0px 5px var(--color-black-light, #0000001a);
  }
  &__chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__header {
    display: flex;
    padding: 24px 24px 10px 24px !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px 24px 0 0;
    border-bottom: solid 1px var(--color-gray-lightest, #e8eaed);
    background-color: var(--color-white-lightest, #ffffff) !important;

    p {
      font-size: var(--font-size-l, 20px);
      font-weight: var(--font-weight-med-bold, 700);
    }
    &__icon {
      color: var(--color-black, #131314) !important;
      width: 20px;
      cursor: pointer;
    }
  }

  &__content {
    padding: 24px !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--color-gray-base, #b5b5bc) !important;
  }

  &__button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 10px;

    &__button {
      color: var(--color-white-lightest, #ffffff) !important;
      font-size: var(--font-size-xs, 12px) !important;
      font-weight: var(--font-weight-bold, 600) !important;
      border-radius: 8px !important;
      height: 28px;
      line-height: 10px !important;

      &.delete-button {
        color: var(--color-black, #131314) !important;
      }
    }
  }

  &__time-selector {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    background-color: var(--color-gray-purple, #f9f8fc);
    border-radius: 16px;
    padding: 10px;

    &__item {
      width: 100%;

      .MuiInputBase-root {
        font-size: 14px;
        height: 40px;
      }

      .MuiInputLabel-root {
        font-size: 14px;
        transform: translate(14px, 8px) scale(1);

        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(14px, -9px) scale(0.75);
        }
      }

      .MuiOutlinedInput-input {
        padding: 10px 14px;
      }

      .MuiSvgIcon-root {
        width: 18px;
      }
    }
  }
}
