.calendar-view-switcher {
  display: flex;
  align-items: center;
  gap: 16px;

  &__button {
    height: 24px !important;
    width: 24px !important;
    border-radius: 3px !important;
    color: var(--color-gray-base) !important;

    &--active {
      color: var(--color-black) !important;
    }
  }
}
