.data-menu-bar {
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  border-right: solid 1px var(--color-gray-lightest, #e8eaed);

  &__menu-container {
    border-top: solid 1px var(--color-gray-lightest, #e8eaed);
    height: 100%;
  }

  &__menu {
    padding: 16px;
    color: var(--color-text, #131314);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--active {
      background-color: var(--color-gray-purple, #f9f8fc);
    }
    p {
      font-weight: var(--font-weight-med-bold, 700);
      padding: 16px 0px 12px 16px;
    }

    &__icon {
      color: var(--color-gray-base, #b5b5bc);
    }
  }
}
