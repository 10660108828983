@import '../../../utils/styles/mixins/_responsive';

.initial-sync-page {
  width: 100%;

  &__left-section {
    &__asset {
      width: 100%;
    }
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    flex: 1;
    height: 100%;
    gap: 30px;
    padding: 20px;

    @include responsive('desktop') {
      padding: 20px 0 20px 100px;
      gap: 60px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    gap: 30px;
    width: 100%;
    color: var(--color-white-lightest, #ffffff);
  }

  &__date-picker {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &__item {
      .MuiInputAdornment-root > button {
        height: auto !important;
        color: var(--color-white-lightest, #ffffff) !important;
      }

      .MuiFormLabel-root {
        color: var(--color-white-lightest, #ffffff) !important;
      }

      .MuiInputBase-root {
        color: var(--color-white-lightest, #ffffff) !important;
      }
    }
  }

  &__title {
    color: var(--color-white-lightest, #ffffff) !important;
    font-family: var(--font-family-nunito) !important;
  }

  &__progress-bar {
    height: 11px !important;
    width: 100% !important;
    border-radius: 14px !important;
  }

  &__btn-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 30px !important;

    @include responsive('desktop') {
      margin-top: auto !important;
    }
  }

  &__btn {
    border-radius: 12px !important;
    width: 100%;
    height: 64px;

    &--skip {
      color: var(--color-white-lightest, #ffffff) !important;
    }
  }
}
