.sleep-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__stages {
    height: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 100px;
    overflow: hidden;
  }

  &__stage {
    height: 100%;

    &--empty {
      width: 100%;
      background-color: var(--color-blue-light);
    }
  }

  &__times {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__time {
    font-size: var(--font-size-xs, 12px);
    color: var(--color-gray-base);
    font-weight: var(--font-weight-semi-bold, 500);
  }
}
